/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import ReactExport from 'react-data-export';
import { Button } from 'primereact/button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IoFunnelOutline, IoMailOpenSharp, IoMailUnread } from 'react-icons/io5';
import Pagination from '../../components/Pagination/Pagination';
import Search from '../../components/Search/Search';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { getFullDateWithHourAndMinutes, getFormatedData } from '../../util/format';
import { useToast } from '../../hooks/Toast';
import './styles.css';
import { Col, Form, Modal } from 'react-bootstrap';
import { toCurrency } from '../../util/Validacoes/Validacoes';
import { BauIdNumero } from '../../api/model/BauIdNumeroType';
import { Spinner } from '../../components/Spinner';
import { Tela } from '../../components/Tela';
import { obterData, obterDataHora } from '../../util/date';

type SMLancada = {
  blEscolta: boolean;
  blIsca: boolean;
  cancelada: boolean;
  contrato: string;
  dataFim: string;
  dataFimReal: string;
  numeroBau: number;
  dataInicio: string;
  dataInicioReal: string;
  empresa: string;
  empresaEscolta: string;
  escolta: string;
  faturamento: string;
  finalizada: boolean;
  Frota: string;
  idSm: number;
  inicializacao: boolean;
  isca: string;
  listaPonto: {
    agendamento: string;
    bairro: string;
    cidade: string;
    complemento: string;
    descricao: string;
    endereco: string;
    numero: number;
    numeroRemessa: string;
    origem: string;
    sequencia: number;
    uf: string;
  }[];
  modeloBauIntercambiavel: string;
  nomeCondutor: string;
  nomeCondutorAuxiliar: string;
  nomeCorretora: string;
  nomeSeguradoraCongelado: string;
  nomeCorretoraCongelado: string;
  nomeEmbarcador: string;
  nomeSeguradora: string;
  nomeTecnologia: string;
  nomeUsuario: string;
  numeroEquipamento: string;
  observacao: string;
  distanciaTotalSm: number;
  tipoOperacaoSM: string;
  operacao: string;
  perfilUsuario: string;
  placa: string;
  placaCarreta: string;
  placaCarreta2: string;
  pontoDestino: string;
  pontoOrigem: string;
  romaneio: string;
  valorCarga: number;
  veiculoEscolta: string;
  visualizacao: boolean;
  usuarioIniciouSm: string;
  usuarioFinalizouSm: string;
  nomeUsuarioCancelamento: string;
  tipoOperacao: string;
  usuarioCadastro: string;
  identificadorCliente: string;
  frotaCliente: string;
  motivoFinalizacao: string;
  dataHoraInclusao: string;
  descricaoCarga: string;
  gerenteConta: string;
  vencimentoConta: string;
  marca: string;
  modeloVeiculo: string;
  corVeiculo: string;
  condutorCPF: string;
  checklistVeiculo: string;
  satVeiculo: string[];
  clienteId: number;
  frota: string;
};

type PesquisaData = {
  dataInicio: string;
  dataFim: string;
  dataInicioLancamento: string;
  dataFimLancamento: string;
  placa: string;
  condutorCpf: string;
  nome: string;
  valorMaximo: number | undefined;
  valorMinimo: number | undefined;
  status: string | string[];
  idSm: number | undefined;
  idUsuario: number;
  idCliente: number | undefined;
  romaneio: string;
  observacao: string;
  viagemIsca: string;
  escolta: string;
  faturamento: string;
  cnpjEmbarcador?: string;
  faturamentoNaoInformado: boolean;
  faturamentoMensalista: boolean;
  faturamentoViagemAvulsa: boolean;
  faturamentoNaoRastreado: boolean;
  faturamentoDesabilitado: boolean;
  condutorNome?: string;
};

export type ExcelGeneration = {
  ySteps?: number;
  xSteps: number;
  columns: { title: string; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined }[][];
};

const ListagemSMLancadas: FC = () => {
  const [data, setData] = useState<SMLancada[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<SMLancada[] | undefined>();
  const [itensPerPage, setItensPerPage] = useState('10');
  const history = useHistory();
  const { page, idUsuario }: any = useParams();
  const location: { state: PesquisaData } = useLocation();
  const [dadosPesquisa, setDadosPesquisa] = useState<PesquisaData>({} as PesquisaData);
  const [loadData, setLoadData] = useState(false);
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();
  const { addToast } = useToast();
  const usuarioLogado = getCookieSessionData().usuarioVO.usuarioId;
  const roleLogado = getCookieSessionData().usuarioVO.role;
  const isEmbarcador = getCookieSessionData().usuarioVO.isEmbarcador;

  const paginate = (pageNumber: number) => {
    history.push({
      pathname: `/listagem-sm-lancadas/${pageNumber}`,
      state: {
        ...location.state,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!loadData) {
          return;
        }
        setLoading(true);
        console.log('--/--', dadosPesquisa);

        const response = await instanceMaquina.get(
          isEmbarcador
            ? '/solicitacao-monitoramento/consultaSmLancada'
            : '/solicitacao-monitoramento/consultaSmLancada-Gerenciador',
          {
            params: dadosPesquisa,
          }
        );

        if (!response.data.length) {
          addToast({
            title: 'Info',
            description: 'Não foi possível encontrar SM utilizando este filtro',
            type: 'info',
          });
          return;
        }

        const removeAndJoin = (array: string[], toRemove: string[]): string => {
          return array.filter((item) => !toRemove.includes(item)).join(', ');
        };

        setData(
          response.data.map((each: SMLancada) => {
            let situacaoStatus;
            if (each.cancelada) {
              situacaoStatus = 'Cancelada';
            } else if (each.finalizada) {
              situacaoStatus = 'Finalizada';
            } else if (each.inicializacao) {
              situacaoStatus = 'Em andamento';
            } else {
              situacaoStatus = 'Pendente';
            }

            // Assuming you want to remove null/empty values from satVeiculo and join into a string
            const satVeiculoString = each.satVeiculo
              ? each.satVeiculo.filter((item: string) => item).join(', ')
              : '';

            return {
              idSm: each.idSm,
              empresa: each.empresa || '',
              placa: each.placa || '',
              nomeTecnologia: each.nomeTecnologia || '',
              placaCarreta: each.placaCarreta || '',
              placaCarreta2: each.placaCarreta2 || '',
              modeloBauIntercambiavel: each.modeloBauIntercambiavel || '',
              nomeCondutor: each.nomeCondutor || '',
              nomeCondutorAuxiliar: each.nomeCondutorAuxiliar || '',
              pontoOrigem: each.pontoOrigem || '',
              pontoDestino: each.pontoDestino || '',
              dataInicio: each.dataInicio ? obterDataHora(each.dataInicio, true) : '',
              dataFim: each.dataFim ? obterDataHora(each.dataFim, true) : '',
              dataInicioReal: each.dataInicioReal ? obterDataHora(each.dataInicioReal, true) : '',
              dataFimReal: each.dataFimReal ? obterDataHora(each.dataFimReal, true) : '',
              valorCarga: each.valorCarga || '',
              escolta: each.escolta || '',
              empresaEscolta: each.empresaEscolta || '',
              observacao: each.observacao || '',
              romaneio: each.romaneio || '',
              viagemIsca: '',
              operacao: each.operacao || '',
              listaPonto: each.listaPonto,
              Frota: '',
              id: '',
              status: '',
              situacao: situacaoStatus,
              inicializacao: each.inicializacao || '',
              visualizacao: each.visualizacao || '',
              cancelada: each.cancelada || '',
              finalizada: each.finalizada || '',
              nomeEmbarcador: each.nomeEmbarcador || '',
              nomeCorretora: each.nomeCorretoraCongelado || each.nomeCorretora || '',
              nomeSeguradora: each.nomeSeguradoraCongelado || each.nomeSeguradora || '',
              isca: each.isca || '',
              blIsca: each.blIsca || '',
              blEscolta: each.blEscolta ? 'Sim' : 'Não',
              contrato: each.contrato || '',
              nomeUsuario: each.nomeUsuario || '',
              perfilUsuario: each.perfilUsuario || '',
              veiculoEscolta: each.veiculoEscolta || '',
              usuarioIniciouSm: each.usuarioIniciouSm || '',
              usuarioFinalizouSm: each.usuarioFinalizouSm || '',
              nomeUsuarioCancelamento: each.nomeUsuarioCancelamento || '',
              tipoOperacao: each.tipoOperacao || '',
              tipoOperacaoSM: each.tipoOperacaoSM || '',
              distanciaTotalSm: each.distanciaTotalSm || '',
              usuarioCadastro: each.usuarioCadastro || '',
              identificadorCliente: each.identificadorCliente || '',
              frotaCliente: each.frotaCliente || '',
              numeroEquipamento: each.numeroEquipamento || '',
              motivoFinalizacao: each.motivoFinalizacao || '',
              dataHoraInclusao: each.dataHoraInclusao || '',
              descricaoCarga: each.descricaoCarga || '',
              gerenteConta: each.gerenteConta || '',
              vencimentoConta: each.vencimentoConta || '',
              frota: each.frota || '',
              marca: each.marca || '',
              modeloVeiculo: each.modeloVeiculo || '',
              corVeiculo: each.corVeiculo || '',
              condutorCPF: each.condutorCPF || '',
              checklistVeiculo:
                each.checklistVeiculo && each.checklistVeiculo.includes('OK')
                  ? ''
                  : each.checklistVeiculo || '',
              // checklistVeiculo: each.checklistVeiculo || '',
              satVeiculo: satVeiculoString, // Converted to string
              clienteId: each.clienteId || '',
            };
          })
        );

        setLoading(false);
        setLoadData(false);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };
    fetchData();
  }, [addToast, dadosPesquisa, loadData, usuarioLogado]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const cargaTotal = data.reduce((acumulador, each) => {
      return acumulador + each.valorCarga;
    }, 0);

    if (roleLogado === 'admin') {
      setExcelGeneration([
        {
          xSteps: 2,
          ySteps: 0,
          columns: [
            {
              title: 'Consulta S.M. Lançadas',
              widthPx: 1060,
              style: { font: { sz: '24', bold: true } },
            },
          ],
          data: [],
        },
        {
          xSteps: 0,
          ySteps: 0,
          columns: [],
          data: [
            [
              {
                value: `Período: ${
                  dadosPesquisa.dataInicio
                    ? obterData(dadosPesquisa.dataInicio)
                    : data[data.length - 1].dataInicio.split(' ')[0]
                } à ${
                  dadosPesquisa.dataFim
                    ? obterData(dadosPesquisa.dataFim)
                    : data[0].dataInicio.split(' ')[0]
                }`,
              },
            ],
            [{ value: `Total de Viagens: ${data.length as number}` }],
            [
              {
                value: `Valor Total: ${cargaTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`,
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 1,
          columns: [
            { title: 'Viagem' },
            { title: 'Cliente' },
            { title: 'Embarcador' },
            { title: 'Corretora' },
            { title: 'Seguradora' },
            { title: 'Veículo' },
            { title: 'Tecnologia' },
            { title: 'Nº Equipamento' },
            { title: 'Condutor' },
            { title: 'Origem' },
            { title: 'Destino' },
            { title: 'Previsão Início' },
            { title: 'Início Real' },
            { title: 'Término' },
            { title: 'Operação' },
            { title: 'Valor Carga' },
            { title: 'Escolta' },
            { title: 'Isca' },
            { title: 'Segunda Isca' },
            { title: 'Imobilizador' },
            { title: 'Observações' },
            { title: 'Contrato' },
            { title: 'Usuário Inclusão' },
            { title: 'Status' },
            { title: 'Usuário GS' },
            { title: 'Escolta Monitorada?' },
            { title: 'Motivo Finalização' },
            { title: 'Data/Hora Inclusão ' },
            { title: 'Descrição Carga ' },
            { title: 'Gerente da Conta' },
            { title: 'Vencimento da Conta' },
            { title: 'Tipo Frota' },
            { title: 'Marca' },
            { title: 'Modelo' },
            { title: 'Cor' },
            { title: 'Cpf Condutor' },
            { title: 'CheckList Veículo' },
            { title: 'Sat Veículo' },
            { title: 'Cód Cliente' },
            { title: 'Previsão de Termino' },
          ],

          data: data.map((each) => [
            { value: each.idSm },
            { value: each.empresa },
            { value: each.nomeEmbarcador },
            { value: each.nomeCorretora },
            { value: each.nomeSeguradora },
            { value: each.placa },
            { value: each.nomeTecnologia },
            { value: each.numeroEquipamento },
            { value: each.nomeCondutor },
            { value: each.pontoOrigem },
            { value: each.pontoDestino },
            { value: each.dataInicio },
            { value: each.dataInicioReal },
            { value: each.dataFimReal },
            { value: each.operacao },
            {
              value: each.valorCarga.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }),
            },
            { value: each.veiculoEscolta },
            { value: each.isca },
            { value: '' }, //segunda isca
            { value: '' }, //imobilizador
            { value: each.observacao },
            { value: each.contrato },
            { value: each.usuarioCadastro }, // Inclusão (usuário que incluiu a sm)
            {
              value:
                each.finalizada === true
                  ? 'Finalizada'
                  : each.inicializacao === true
                  ? 'Em Andamento'
                  : each.cancelada === true
                  ? 'Cancelada'
                  : 'Pendente',
            },
            {
              value: each.nomeUsuarioCancelamento
                ? each.nomeUsuarioCancelamento
                : each.usuarioFinalizouSm
                ? each.usuarioFinalizouSm
                : each.usuarioIniciouSm
                ? each.usuarioIniciouSm
                : '',
            }, // nomeUsuario ou perfilUsuario ?  --> Usuário GS que inicia e finaliza sm
            { value: each.blEscolta },
            { value: each.motivoFinalizacao }, //
            { value: each.dataHoraInclusao },
            { value: each.descricaoCarga },
            { value: each.gerenteConta }, //
            { value: each.vencimentoConta },
            { value: each.frota },
            { value: each.marca }, //
            { value: each.modeloVeiculo },
            { value: each.corVeiculo },
            { value: each.condutorCPF }, //
            { value: each.checklistVeiculo },
            { value: each.satVeiculo },
            { value: each.clienteId },
            { value: each.dataFim },
          ]),
        },
      ]);
    }

    if (
      (roleLogado === 'user' && isEmbarcador === false) ||
      roleLogado === 'corretora' ||
      roleLogado === 'seguradora'
    ) {
      setExcelGeneration([
        {
          xSteps: 2,
          ySteps: 0,
          columns: [
            {
              title: 'Consulta S.M. Lançadas',
              widthPx: 1060,
              style: { font: { sz: '24', bold: true } },
            },
          ],
          data: [],
        },
        {
          xSteps: 0,
          ySteps: 0,
          columns: [],
          data: [
            [
              {
                value: `Período: ${
                  dadosPesquisa.dataInicio
                    ? obterData(dadosPesquisa.dataInicio)
                    : data[data.length - 1].dataInicio.split(' ')[0]
                } à ${
                  dadosPesquisa.dataFim
                    ? obterData(dadosPesquisa.dataFim)
                    : data[0].dataInicio.split(' ')[0]
                }`,
              },
            ],
            [{ value: `Total de Viagens: ${data.length as number}` }],
            [
              {
                value: `Valor Total: ${cargaTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`,
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 1,
          columns: [
            { title: 'S.M nº' },
            { title: 'Embarcador' },
            { title: 'Veículo' },
            { title: 'Técnologia' },
            { title: 'Carreta(1)' },
            { title: 'Carreta(2)' },
            { title: 'Baú Intercambiável' },
            { title: 'Condutor(1)' },
            { title: 'Condutor(2)' },
            { title: 'Origem' },
            { title: 'Destino' },
            { title: 'Previsão Início' },
            { title: 'Previsão Término' },
            { title: 'Início' },
            { title: 'Término' },
            { title: 'Valor Carga' },
            { title: 'Escolta' },
            { title: 'Isca' },
            { title: 'Segunda Isca' },
            { title: 'Imobilizador' },
            { title: 'Observações' },
            { title: 'Operação' },
            { title: 'Frota' },
            { title: 'N° ID' },
            { title: 'Status' },
          ],
          data: data.map((each) => {
            return [
              { value: `${each.idSm}` },
              { value: each.nomeEmbarcador },
              { value: each.placa },
              { value: each.nomeTecnologia },
              { value: each.placaCarreta },
              { value: each.placaCarreta2 },
              { value: each.numeroBau },
              { value: each.nomeCondutor },
              { value: each.nomeCondutorAuxiliar },
              { value: each.pontoOrigem },
              { value: each.pontoDestino },
              { value: each.dataInicio },
              { value: each.dataFim },
              { value: each.dataInicioReal },
              { value: each.dataFimReal },
              { value: toCurrency(each.valorCarga) },
              { value: each.veiculoEscolta },
              { value: each.isca },
              { value: '' }, // segunda isca
              { value: '' }, // imobilizador
              { value: each.observacao },
              { value: each.operacao },
              { value: each.Frota },
              { value: each.identificadorCliente },
              {
                value:
                  each.finalizada === true
                    ? 'Finalizada'
                    : each.cancelada === true
                    ? 'Cancelada'
                    : each.inicializacao === true
                    ? 'Em Andamento'
                    : 'Pendente',
              },
            ];
          }),
        },
      ]);
    } else if (roleLogado === 'user' && isEmbarcador === true) {
      setExcelGeneration([
        {
          xSteps: 2,
          ySteps: 0,
          columns: [
            {
              title: 'Consulta S.M. Lançadas',
              widthPx: 1060,
              style: { font: { sz: '24', bold: true } },
            },
          ],
          data: [],
        },
        {
          xSteps: 0,
          ySteps: 0,
          columns: [],
          data: [
            [
              {
                value: `Período: ${
                  dadosPesquisa.dataInicio
                    ? obterData(dadosPesquisa.dataInicio)
                    : data[data.length - 1].dataInicio.split(' ')[0]
                } à ${
                  dadosPesquisa.dataFim
                    ? obterData(dadosPesquisa.dataFim)
                    : data[0].dataInicio.split(' ')[0]
                }`,
              },
            ],
            [{ value: `Total de Viagens: ${data.length as number}` }],
            [
              {
                value: `Valor Total: ${cargaTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}`,
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 1,
          columns: [
            { title: 'S.M nº' },
            { title: 'Transportador' },
            { title: 'Remessa' },
            { title: 'Valor Carga' },
            { title: 'Origem' },
            { title: 'Origem/UF' },
            { title: 'Destino' },
            { title: 'Destino/UF' },
            { title: 'Rota' },
            { title: 'Placa Cavalo' },
            { title: 'Placa Carreta 1' },
            { title: 'Placa Carreta 2' },
            { title: 'Condutor 1' },
            { title: 'Condutor 2' },
            { title: 'Início Real' },
            { title: 'Término Real' },
            { title: 'Situação' },
            { title: 'Tipo Operação' },
            { title: 'KM' },
            { title: 'Término TSF' },
            { title: 'Operação' },
          ],
          data: data.map((each) => {
            let remessa = '';
            let rota = '';
            if (each.listaPonto.length) {
              each.listaPonto?.forEach((lP: any, index: number) => {
                if (index > 0) {
                  rota += lP.descricao ? `/${lP.descricao}` : '';
                  remessa += lP.numeroRemessa ? `/${lP.numeroRemessa}` : '';
                } else {
                  rota += lP.descricao ? `${lP.descricao}` : '';
                  remessa += lP.numeroRemessa ? `${lP.numeroRemessa}` : '';
                }
              });
            } else {
              rota += `${each.pontoOrigem}/${each.pontoDestino}`;
            }

            return [
              { value: `${each.idSm}` },
              { value: each.empresa },
              { value: remessa },
              { value: toCurrency(each.valorCarga) },
              { value: each.pontoOrigem.split('/')[0] },
              { value: each.pontoOrigem.split('/')[1] },
              { value: each.pontoDestino.split('/')[0] },
              { value: each.pontoDestino.split('/')[1] },
              { value: rota },
              { value: each.placa },
              { value: each.placaCarreta },
              { value: each.placaCarreta2 },
              { value: each.nomeCondutor },
              { value: each.nomeCondutorAuxiliar },
              { value: each.dataInicioReal },
              { value: each.dataFimReal },
              {
                value:
                  each.finalizada === true
                    ? 'Finalizada'
                    : each.inicializacao === true
                    ? 'Em Andamento'
                    : each.cancelada === true
                    ? 'Cancelada'
                    : 'Pendente',
              },
              { value: each.tipoOperacaoSM ?? '' },
              { value: `${each.distanciaTotalSm ?? ''}` },
              { value: '' },
              { value: each.operacao },
            ];
          }),
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    const dataPesquisa: PesquisaData = {} as PesquisaData;

    if (location.state) {
      dataPesquisa.dataInicio = location.state.dataInicio;
      dataPesquisa.dataFim = location.state.dataFim;

      dataPesquisa.dataInicioLancamento = location.state.dataInicioLancamento;
      dataPesquisa.dataFimLancamento = location.state.dataFimLancamento;

      if (location.state.placa) {
        dataPesquisa.placa = location.state.placa;
      }
      if (location.state.condutorCpf) {
        dataPesquisa.condutorCpf = location.state.condutorCpf
          .replaceAll('.', '')
          .replaceAll('-', '');
      }
      if (location.state.condutorNome) {
        dataPesquisa.condutorNome = location.state.condutorNome;
      }
      if (location.state.valorMaximo) {
        dataPesquisa.valorMaximo = parseInt(location.state.valorMaximo.toString(), 10);
      }
      if (location.state.valorMinimo) {
        dataPesquisa.valorMinimo = parseInt(location.state.valorMinimo.toString(), 10);
      }
      if (Array.isArray(location.state.status)) {
        dataPesquisa.status = JSON.stringify(location.state.status);
      } else {
        dataPesquisa.status = location.state.status;
      }
      if (location.state.idSm) {
        dataPesquisa.idSm = location.state.idSm;
      }
      if (location.state.idCliente && !isEmbarcador) {
        dataPesquisa.idCliente = location.state.idCliente;
      }
      if (location.state.romaneio) {
        dataPesquisa.romaneio = location.state.romaneio;
      }
      if (location.state.observacao) {
        dataPesquisa.observacao = location.state.observacao;
      }
      if (location.state.escolta) {
        dataPesquisa.escolta = location.state.escolta;
      }
      if (location.state.viagemIsca) {
        dataPesquisa.viagemIsca = location.state.viagemIsca;
      }
      if (location.state.idUsuario) {
        dataPesquisa.idUsuario = location.state.idUsuario;
      }
      if (location.state.cnpjEmbarcador && isEmbarcador) {
        dataPesquisa.cnpjEmbarcador = location.state.cnpjEmbarcador;
      }
      if (!isEmbarcador) {
        dataPesquisa.faturamento = location.state.faturamento;
        dataPesquisa.faturamentoNaoInformado = location.state.faturamentoNaoInformado;
        dataPesquisa.faturamentoMensalista = location.state.faturamentoMensalista;
        dataPesquisa.faturamentoViagemAvulsa = location.state.faturamentoViagemAvulsa;
        dataPesquisa.faturamentoNaoRastreado = location.state.faturamentoNaoRastreado;
        dataPesquisa.faturamentoDesabilitado = location.state.faturamentoDesabilitado;
      }
    }

    setDadosPesquisa(dataPesquisa);
    setLoadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (searchValue) {
      setFilteredData(
        data.filter(
          (each) =>
            each.idSm.toString().includes(searchValue) ||
            (each.placa && each.placa.toLowerCase().includes(searchValue.toLowerCase())) ||
            (each.dataFimReal && each.dataFimReal.includes(searchValue)) ||
            (each.dataInicioReal && each.dataInicioReal.includes(searchValue)) ||
            (each.empresa && each.empresa.toLowerCase().includes(searchValue.toLowerCase())) ||
            (each.pontoDestino &&
              each.pontoDestino.toLowerCase().includes(searchValue.toLowerCase())) ||
            (each.pontoOrigem && each.pontoOrigem.toLowerCase().includes(searchValue.toLowerCase()))
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchValue, data]);

  const handleVisualizar = async (
    id: number,
    cancelada: boolean,
    finalizada: boolean,
    inicializada: boolean,
    visualizada: boolean
  ) => {
    try {
      if (cancelada || finalizada) {
        history.push(`/visualizar-sm-cancelada/${id}`);
      } else if (inicializada) {
        history.push(`/editar-sm-emandamento/${id}`);
      } else {
        history.push(`/sm/editar/${id}`);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Tela
      nome="Consultar SM Lançadas"
      caminho="Consultas/Relatórios > Consultar SM Lançadas"
      filename="SMLancadas"
      excelGeneration={excelGeneration}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={filteredData ? filteredData.length : 0}
      paginate={paginate}
      currentPage={page}
      onClickVoltar={() => history.push('/consultar-sm-lancadas')}
      disabledExport={!filteredData?.length}
    >
      <div className="content-container">
        <div className="painel">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IoFunnelOutline
              className="filter-icon"
              size={21}
              onClick={() => history.push('/consultar-sm-lancadas')}
            />

            <Search
              disabled={!data}
              setLoading={setLoading}
              setSearch={setSearch}
              setSearchValue={setSearchValue}
              search={search}
            />
          </div>
          <div className="table-responsive" style={{ flex: 1 }}>
            {loading ? (
              <Spinner />
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Nº SM</th>
                    <th>Origem</th>
                    <th>Destino</th>
                    <th>Placa</th>
                    <th>Empresa</th>
                    <th>Início Real</th>
                    <th>Término Real</th>
                    <th>Situação</th>
                    <th style={{ width: 125 }} />
                  </tr>
                </thead>

                <tbody>
                  {filteredData &&
                    filteredData
                      .slice(
                        parseInt(itensPerPage, 10) * page - parseInt(itensPerPage, 10),
                        parseInt(itensPerPage, 10) * page
                      )
                      .map((each, index) => {
                        return (
                          <tr key={index}>
                            <td>{each.idSm}</td>
                            <td>{each.pontoOrigem}</td>
                            <td>{each.pontoDestino}</td>
                            <td>{each.placa}</td>
                            <td>{each.empresa}</td>
                            <td>{each.dataInicioReal}</td>
                            <td>{each.dataFimReal}</td>
                            {each.finalizada === true ? (
                              <td>Finalizada</td>
                            ) : each.inicializacao === true ? (
                              <td>Em Andamento</td>
                            ) : each.cancelada === true ? (
                              <td>Cancelada</td>
                            ) : (
                              <td>Pendente</td>
                            )}
                            <td>
                              <Button
                                tooltip="Visualizar SM"
                                tooltipOptions={{ position: 'bottom' }}
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                  padding: 0,
                                  paddingRight: 5,
                                  marginBottom: 2,
                                }}
                                onClick={() =>
                                  handleVisualizar(
                                    each.idSm,
                                    each.cancelada,
                                    each.finalizada,
                                    each.inicializacao,
                                    each.visualizacao
                                  )
                                }
                              >
                                {!each.visualizacao ? (
                                  <IoMailUnread size={22} color="#9F9F9F" />
                                ) : (
                                  <IoMailOpenSharp size={22} color="#9F9F9F" />
                                )}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Tela>
  );
};

export default ListagemSMLancadas;
