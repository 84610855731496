/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Form, Col, Button, Spinner, Modal } from 'react-bootstrap';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { useHistory, useParams } from 'react-router-dom';
import { GoInfo } from 'react-icons/go';
import Switch from 'react-bootstrap/esm/Switch';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ContainerPesquisarCondutores } from '../../components/ComponentPesquisarCondutores';
import { ContainerPesquisarAjudante } from '../../components/ComponentPesquisarAjudante';
import { ContainerPesquisarVeiculos } from '../../components/ComponentPesquisarVeiculos';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import { nowToZoned } from '../../util/date';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

export type ErrosDataPesquisa = {
  mensagemErro: string;
  listaErros: string[];
};

export const EnviarPesquisa = () => {
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { TRANSPORTADOR } = PERMISSION;

  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;
  const [isAjudanteTabFocused, setIsAjudanteTabFocused] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadErro, setIsDialogCadErro] = useState(false);
  const [isTermoOK, setIsTermoOK] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [tipoPesquisa, setTipoPesquisa] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [dialogBlock, setDialogBlock] = useState(false);
  const [condutorResposta, setCondutorResposta] = useState<any>(false);
  const [condutorRespostaDialog, setCondutorRespostaDialog] = useState(false);
  const [centroCusto, setCentroCusto] = useState('');

  const [idPesquisaEnviada, setIdPesquisaEnviada] = useState();
  const [itensVigentes, setItensVigentes] = useState<string[]>([]);
  const [itensPesquisasEnviadas, setItensPesquisasEnviadas] = useState<string[]>([]);
  const [dialogConsultaOuPesquisa, setDialogConsultaOuPesquisa] = useState(false);
  const [habilitarPesquisaVigente, setHabilitarPesquisaVigente] = useState(false);
  const [habilitarPesquisaIntegrada, setHabilitarPesquisaIntegrada] = useState(false);
  const [flagUtilizaCentroCusto, setFlagUtilizaCentroCusto] = useState(false);
  const { id } = useParams<any>();

  const [dataErros, setDataErros] = useState<ErrosDataPesquisa | undefined>({
    mensagemErro: '',
    listaErros: [],
  });

  const history = useHistory();
  const ajudanteRef = useRef<{
    validation: () => boolean;
    reset: () => void;
    ajudante: {
      nome?: string;
      idAjudante: number;
      cpf: string;
      bloqueado: boolean;
      sexo: string;
    };
  } | null>(null);

  const condutorRef = useRef<{
    reset: () => void;
    validation: () => boolean;
    condutor: {
      validadeCnh?: string;
      id: number;
      nome?: string;
      cpf: string;
      bloqueado: boolean;
      sexo: string;
      centroCusto: string;
    };
  } | null>(null);

  const veiculosRef = useRef<{
    reset: () => void;
    validation: () => boolean;
    veiculo: {
      id: number;
      placa?: string;
      modelo?: string;
      bloqueado: boolean;
      centroCusto: string;
    };
    carreta1: {
      id: number;
      placa?: string;
      bloqueado: boolean;
      centroCusto: string;
    };
    carreta2: {
      id: number;
      placa?: string;
      bloqueado: boolean;
      centroCusto: string;
    };
  } | null>(null);

  const disabled = !habilitarPesquisaIntegrada && !!id;

  useEffect(() => {
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.ANALISE_DE_PERFIL.ENVIAR_PESQUISA] }],
      'inserir'
    );
  }, []);

  useEffect(() => {
    const getParticularidades = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`/cliente/getParticularidadesCliente`, {
          params: {
            cliente: clienteId,
          },
        });
        if (response.data.flagHabilitaPesquisaIntegrada) {
          history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
        }

        setHabilitarPesquisaVigente(response.data.flagHabilitaPesquisasConsultasVigentes);
        setHabilitarPesquisaIntegrada(response.data.flagHabilitaPesquisaIntegrada);
        setFlagUtilizaCentroCusto(response.data.flagUtilizaCentroCusto);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getParticularidades();
  }, []);

  useEffect(() => {
    const loadDadosCliente = async () => {
      if (clienteId) {
        try {
          const response = await axios.get(`/cliente/${clienteId}`);
          const centroCustoString = response.data.centroCusto;

          const centroCustoArray = centroCustoString
            .split(';')
            .filter((item: string) => item.trim() !== '');

          setCentroCusto(centroCustoArray);
        } catch (err: any) {
          console.error('Erro ao carregar dados do cliente:', err);
        }
      }
    };
    loadDadosCliente();
  }, [id]);

  const validaPesquisaVigente = async (data: any) => {
    return false;
  };

  const checaBlacklist = async () => {
    try {
      if (
        ajudanteRef.current?.ajudante.cpf &&
        ajudanteRef.current?.ajudante.nome &&
        isAjudanteTabFocused === true
      ) {
        const response = await axios.get(
          `/blacklist/verificarCpf?cpf=${ajudanteRef.current?.ajudante.cpf}`
        );
        return response.data
          ? `Ajudante ${ajudanteRef.current?.ajudante.nome} encontra-se ${
              ajudanteRef.current?.ajudante.sexo === 'F' ? 'bloqueada' : 'bloqueado'
            }. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02).`
          : 'não consta';
      }
      if (
        condutorRef.current?.condutor.cpf &&
        condutorRef.current?.condutor.nome &&
        isAjudanteTabFocused === false
      ) {
        const response = await axios.get(
          `/blacklist/verificarCpf?cpf=${condutorRef.current?.condutor.cpf}`
        );
        return response.data
          ? `${condutorRef.current?.condutor.sexo === 'F' ? 'Condutora ' : 'Condutor '} ${
              condutorRef.current?.condutor.nome
            } encontra-se ${
              condutorRef.current?.condutor.sexo === 'F' ? 'bloqueada' : 'bloqueado'
            }. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02).`
          : 'não consta';
      }
      return 'não consta';
    } catch (error) {
      console.log(error);
      return 'não consta';
    }
  };

  const checaVigencia = async () => {
    const vigentes = [];
    const itensEnviados = [];

    if (condutorRef.current?.condutor && !isAjudanteTabFocused) {
      const vigente = await validaPesquisaVigente({
        type: 'idCondutor',
        id: condutorRef.current?.condutor.id,
      });
      if (vigente === true) {
        vigentes.push(
          condutorRef.current?.condutor != undefined
            ? `${condutorRef.current?.condutor.nome} ${condutorRef.current?.condutor.cpf}`
            : ''
        );
      }
      itensEnviados.push(
        condutorRef.current?.condutor != undefined
          ? `${condutorRef.current?.condutor.nome} ${condutorRef.current?.condutor.cpf}`
          : ''
      );
    } else if (ajudanteRef.current?.ajudante && isAjudanteTabFocused) {
      const vigente = await validaPesquisaVigente({
        type: 'idAjudante',
        id: ajudanteRef.current.ajudante.idAjudante,
      });
      if (vigente === true) {
        vigentes.push(
          ajudanteRef.current?.ajudante.nome != undefined
            ? `${ajudanteRef.current?.ajudante.nome} ${ajudanteRef.current?.ajudante.cpf}`
            : ''
        );
      }
      itensEnviados.push(
        ajudanteRef.current?.ajudante.nome != undefined
          ? `${ajudanteRef.current?.ajudante.nome} ${ajudanteRef.current?.ajudante.cpf}`
          : ''
      );
    }
    if (veiculosRef.current?.veiculo) {
      const vigente = await validaPesquisaVigente({
        type: 'idVeiculo',
        id: veiculosRef.current?.veiculo.id,
      });
      if (vigente === true) {
        vigentes.push(
          veiculosRef.current?.veiculo.placa != undefined
            ? `${veiculosRef.current?.veiculo.placa} ${veiculosRef.current?.veiculo.modelo}`
            : ''
        );
      }
      itensEnviados.push(
        veiculosRef.current?.veiculo.placa != undefined
          ? `${veiculosRef.current?.veiculo.placa} ${veiculosRef.current?.veiculo.modelo}`
          : ''
      );
    }
    if (veiculosRef.current?.carreta1) {
      const vigente = await validaPesquisaVigente({
        type: 'idCarreta',
        id: veiculosRef.current?.carreta1.id,
      });
      if (vigente === true) {
        vigentes.push(
          veiculosRef.current?.carreta1.placa != undefined
            ? veiculosRef.current?.carreta1.placa
            : ''
        );
      }
      itensEnviados.push(
        veiculosRef.current?.carreta1.placa != undefined ? veiculosRef.current?.carreta1.placa : ''
      );
    }
    if (veiculosRef.current?.carreta2) {
      const vigente = await validaPesquisaVigente({
        type: 'idCarreta2',
        id: veiculosRef.current?.carreta2.id,
      });
      if (vigente === true) {
        vigentes.push(
          veiculosRef.current?.carreta2.placa != undefined
            ? veiculosRef.current?.carreta2.placa
            : ''
        );
      }
      itensEnviados.push(
        veiculosRef.current?.carreta2.placa != undefined ? veiculosRef.current?.carreta2.placa : ''
      );
    }
    setItensVigentes(vigentes);
    setItensPesquisasEnviadas(itensEnviados);
    return vigentes;
  };

  const handleSubmit = async (event: any, novaPesquisa?: boolean, tipoPesquisa = 'Pesquisa') => {
    event.preventDefault();

    // PESQUISA AJUDANTE
    if (isAjudanteTabFocused) {
      console.log(1);

      handleRequest(
        {
          ajudante: ajudanteRef.current?.ajudante,
          idCliente: clienteId,
          idUsuario: usuarioId,
          cadastrarPesquisaNova: novaPesquisa || false,
        },
        'este ajudante',
        tipoPesquisa
      );
    }
    // PESQUISA NÃO AJUDANTE
    else {
      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null
      ) {
        console.log(2);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }

      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo === null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null
      ) {
        console.log(3);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }

      if (
        condutorRef.current?.condutor === null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null
      ) {
        console.log(4);
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }

      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 === null
      ) {
        console.log(5);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }

      if (
        veiculosRef.current?.veiculo != null &&
        condutorRef.current?.condutor === null &&
        veiculosRef.current?.carreta1 === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        console.log(6);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            veiculo: veiculosRef.current?.veiculo,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'este veículo',
          tipoPesquisa
        );
      }
      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo === null &&
        veiculosRef.current?.carreta1 === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        console.log(7);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'este condutor',
          tipoPesquisa
        );
      }
      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo === null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 === null
      ) {
        console.log(8);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }

      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        console.log(9);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            veiculo: veiculosRef.current?.veiculo,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }
      if (
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.veiculo === null &&
        condutorRef.current?.condutor === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        console.log(10);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'esta carreta',
          tipoPesquisa
        );
      }
      if (
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null &&
        veiculosRef.current?.veiculo === null &&
        condutorRef.current?.condutor === null
      ) {
        console.log(11);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }
      if (
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 === null &&
        veiculosRef.current?.veiculo != null &&
        condutorRef.current?.condutor === null
      ) {
        console.log(12);

        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa || false,
          },
          'um item ou mais',
          tipoPesquisa
        );
      }
    }
    setIsAjudanteTabFocused(false);
  };

  const handleClick = async (event: any) => {
    if (isAjudanteTabFocused) {
      if (!ajudanteRef.current?.ajudante) {
        addToast({
          title: 'Erro',
          description: 'Selecione um Ajudante/Funcionário',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
      if (!ajudanteRef.current.validation()) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios.',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    } else {
      if (!condutorRef.current || !veiculosRef.current) return;
      if (!veiculosRef.current.validation() || !condutorRef.current.validation()) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios.',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }
    if (condutorRef.current?.condutor?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(
        `${condutorRef.current?.condutor.sexo === 'F' ? 'Condutora ' : 'Condutor '} ${
          condutorRef.current?.condutor.nome
        } está ${condutorRef.current?.condutor.sexo === 'F' ? 'bloqueada' : 'bloqueado'}.`
      );
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (ajudanteRef.current?.ajudante?.bloqueado && isAjudanteTabFocused) {
      setMensagem(
        `Ajudante ${ajudanteRef.current?.ajudante.nome} está ${
          ajudanteRef.current?.ajudante.sexo === 'F' ? 'bloqueada' : 'bloqueado'
        }.`
      );
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (veiculosRef.current?.veiculo?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(`Veículo ${veiculosRef.current?.veiculo.placa} está bloqueado.`);
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (veiculosRef.current?.carreta1?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(`Carreta(1) ${veiculosRef.current?.carreta1.placa} está bloqueada.`);
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (veiculosRef.current?.carreta2?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(`Carreta(2) ${veiculosRef.current?.carreta2.placa} está bloqueada.`);
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    const constaBlacklist = await checaBlacklist();
    if (!constaBlacklist?.includes('não consta')) {
      setMensagem(constaBlacklist || '');
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    const vigentes = await checaVigencia();
    if (!vigentes.length) {
      setTipoPesquisa('Pesquisa');
      handleSubmit(event, false, 'Pesquisa');
    } else {
      setDialogConsultaOuPesquisa(true);
    }
  };

  const handleRequest = async (data: any, message: string, tipoPesquisa = 'Pesquisa') => {
    setLoading(true);

    console.log('data', data);

    try {
      const response = await axios.post('/pesquisa/integrada', {
        ...data,
        pesquisaManual: false,
        tipoPesquisa,
      });

      if (response.data.solicitationCodeDcnh && response.data.solicitationCodeDprocesso) {
        setCondutorResposta(response.data);
        setCondutorRespostaDialog(true);

        setLoading(false);
        return;
      }

      if (response.data.statusCode !== 200) {
        setLoading(false);
        setIsDialogCadErro(true);

        setDataErros({
          mensagemErro: response.data.message,
          listaErros: response.data.dminerErrors,
        });
      } else {
        if (
          response.data.mensagem?.includes('Consulta') ||
          response.data.mensagemAjudante?.includes('Consulta') ||
          response.data.mensagemCondutor?.includes('Consulta') ||
          response.data.mensagemVeiculo?.includes('Consulta') ||
          response.data.mensagemCarreta?.includes('Consulta') ||
          response.data.mensagemCarreta2?.includes('Consulta')
        ) {
          setTipoPesquisa('Consulta');
        } else {
          setTipoPesquisa('Pesquisa');
        }

        setIdPesquisaEnviada(response.data.solicitationCode);
        setIsDialogCadOK(true);
      }
    } catch (error: any) {
      if (
        error.response.data.message?.includes('processamento') ||
        error.response.data.message?.includes('Constam resultados')
      ) {
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: `já existe uma pesquisa em andamento para ${message}`,
          listaErros: null as any,
        });
      } else {
        const message = error.response.data.message?.split('[')[2].split(']')[0];
        const msg = message.split(',')[1].split('=')[1];

        addToast({
          title: 'Erro!',
          description: error.response.data.message.includes('DminerErroPesquisaVO')
            ? `Atenção: "${msg}"`
            : error.response.data.message,
          type: 'error',
        });
      }
    } finally {
      if (habilitarPesquisaIntegrada) {
        history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
      } else {
        history.replace('/enviar-pesquisa/condutor');
      }
      setLoading(false);
      return;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header={
          <>
            <p>{tipoPesquisa} enviada para:</p>
            <li style={{ fontSize: '15px', listStyleType: 'none' }}>
              <p>Condutor:</p>
              <p>
                Status:{' '}
                {condutorResposta.solicitationCodeDprocesso >= 200 &&
                condutorResposta.solicitationCodeDprocesso < 300
                  ? 'Enviado com sucesso'
                  : 'Não foi possivel enviar'}
              </p>
              <p>Número: {condutorResposta.codPesquisaIntegradaDprocesso}</p>
            </li>

            <li style={{ fontSize: '15px', listStyleType: 'none' }}>
              <p>Cnh:</p>
              <p>
                Status:{' '}
                {condutorResposta.solicitationCodeDcnh >= 200 &&
                condutorResposta.solicitationCodeDcnh < 300
                  ? 'Enviado com sucesso'
                  : 'Não foi possivel enviar'}
              </p>
              <p>Número: {condutorResposta.codPesquisaIntegradaDcnh}</p>
            </li>
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p />
            <Button
              onClick={() => {
                setCondutorRespostaDialog(false);
              }}
            >
              OK
            </Button>
          </div>
        }
        visible={condutorRespostaDialog}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setCondutorRespostaDialog(false);
        }}
      />

      <Dialog
        header={
          <>
            <p>{tipoPesquisa} enviada com sucesso para:</p>
            {itensPesquisasEnviadas ? (
              <>
                {itensPesquisasEnviadas.map((each: string) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Número: {idPesquisaEnviada}</p>
            <Button
              onClick={() => {
                setIsDialogCadOK(false);
              }}
            >
              OK
            </Button>
          </div>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />
      <Dialog
        header={
          <>
            <p>{mensagem}</p>
          </>
        }
        footer={
          <Button
            onClick={() => {
              setDialogBlock(false);
              setMensagem('');
            }}
          >
            OK
          </Button>
        }
        visible={dialogBlock}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setDialogBlock(false);
          setMensagem('');
        }}
      />

      <Dialog
        header={
          <>
            <p>{`Não foi possível enviar pesquisa. `}</p>
            {dataErros?.listaErros ? (
              <>
                {dataErros.listaErros.map((each) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        closable={false}
        footer={
          <>
            <Button
              onClick={() => {
                setIsDialogCadErro(false);
              }}
            >
              OK
            </Button>
          </>
        }
        visible={isDialogCadErro}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />

      <Dialog
        header={
          <div>
            <p>Já existe pesquisa vigente para:</p>
            <br />
            {itensVigentes.map((each: string) => (
              <li>{each}</li>
            ))}
            <br />
          </div>
        }
        closable={false}
        footer={
          <>
            {habilitarPesquisaVigente ? (
              <>
                <p>O que deseja fazer?</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ color: 'black', fontSize: '12px' }}
                    onClick={(e: any) => {
                      setTipoPesquisa('Pesquisa');
                      handleSubmit(e, true, 'Pesquisa');
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Nova Pesquisa
                  </Button>
                  <Button
                    style={{ color: 'black', fontSize: '12px' }}
                    onClick={(e: any) => {
                      setTipoPesquisa('Consulta');
                      handleSubmit(e, false, 'Consulta');
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Consulta
                  </Button>
                  <Button
                    style={{
                      background: 'white',
                      border: '1px solid black',
                      color: 'black',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{
                    background: 'white',
                    border: '1px solid black',
                    color: 'black',
                    fontSize: '11px',
                  }}
                  onClick={() => {
                    setDialogConsultaOuPesquisa(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </>
        }
        visible={dialogConsultaOuPesquisa}
        style={{ width: '40vw' }}
        modal
        onHide={() => {
          setDialogConsultaOuPesquisa(false);
        }}
      />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Enviar Pesquisa</h2>
        </div>

        {isTermoOK === false ? (
          <>
            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>{' '}
            <p style={{ textAlign: 'justify' }}>
              Prezado Cliente, ATENÇÃO! A gestão do uso do nosso serviço de pesquisas é de inteira
              responsabilidade do cliente (usuário), ou seja, a vigência de cada pesquisa, a correta
              configuração do tipo de pesquisa, ou do tipo de item (Funcionário, Agregado, Autônomo,
              Frota Fixa, Veículo de Agregado, Veículo de Terceiro), e principalmente o controle das
              ATUALIZAÇÕES AUTOMÁTICAS, são única e exclusivamente de responsabilidade do SEGURADO.
              Em casos de dúvidas, nossa equipe técnica está inteiramente à disposição para auxiliar
              através do telefone (21)3644-7000.
            </p>{' '}
            <Form.Group>
              <Form.Check
                checked={isTermoOK}
                onChange={(event) => {
                  setIsTermoOK(!isTermoOK);
                }}
                defaultChecked={!isTermoOK}
                type="checkbox"
                style={{ color: 'red' }}
                id="exampleCheck1"
                label="Li e concordo com os termos."
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>
          </>
        ) : (
          <>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <div className="painel">
                {disabled ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p>Pesquisa Integrada desativada.</p>

                    <Tooltip target=".info">
                      As Pesquisas Integradas estão desativadas para este cliente. Limpe o item
                      existente para liberar as outras opções de pesquisa.
                    </Tooltip>
                    <button
                      className="info"
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        height: 20,
                        display: 'flex',
                      }}
                      type="button"
                    >
                      <GoInfo color="#737373" size="13px" />
                    </button>
                  </div>
                ) : null}
                {habilitarPesquisaIntegrada ? (
                  <>
                    <Tabs defaultActiveKey="pesquisaIntegrada" id="uncontrolled-tab-example">
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/condutor-veiculo-carreta');

                          condutorRef?.current?.reset();
                          veiculosRef?.current?.reset();
                        }}
                        eventKey="pesquisaIntegrada"
                        title="Pesquisar Condutor / Veiculo / Carreta"
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarCondutores
                              ref={condutorRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              setLoading={setLoading}
                              integrada
                              habilitarPesquisaIntegrada={habilitarPesquisaIntegrada}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              centroCusto={centroCusto}
                            />
                          </form>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarVeiculos
                              ref={veiculosRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              setLoading={setLoading}
                              habilitarPesquisaIntegrada={habilitarPesquisaIntegrada}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              centroCusto={centroCusto}
                            />
                          </form>
                        </div>
                      </Tab>
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/ajudante');
                          setIsAjudanteTabFocused(true);

                          ajudanteRef?.current?.reset();
                        }}
                        eventKey="pesquisaFuncionario"
                        title="Pesquisar Ajudante/Funcionário (Não Motorista)"
                        onExit={() => setIsAjudanteTabFocused(false)}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form>
                            <ContainerPesquisarAjudante
                              ref={ajudanteRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              setLoading={setLoading}
                              centroCusto={centroCusto}
                            />
                          </form>
                        </div>
                      </Tab>
                    </Tabs>
                    <hr />
                    <Form.Row className="container-buttons justify-content-end">
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-cancelar"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Cancelar
                        </Button>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-enviar"
                          onClick={(event: any) => {
                            if (canInsert) {
                              handleClick(event);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        >
                          Confirmar
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <Tabs defaultActiveKey="pesquisaCondutores" id="uncontrolled-tab-example">
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/condutor');
                        }}
                        eventKey="pesquisaCondutores"
                        title="Pesquisar Condutores"
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarCondutores
                              ref={condutorRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              setLoading={setLoading}
                              habilitarPesquisaIntegrada={habilitarPesquisaIntegrada}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              centroCusto={centroCusto}
                            />
                          </form>
                        </div>
                      </Tab>
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/veiculo');
                        }}
                        eventKey="pesquisaVeiculos"
                        title="Pesquisar Veículos"
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarVeiculos
                              ref={veiculosRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              setLoading={setLoading}
                              habilitarPesquisaIntegrada={habilitarPesquisaIntegrada}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              centroCusto={centroCusto}
                            />
                          </form>
                        </div>
                      </Tab>
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/carreta');
                        }}
                        eventKey="pesquisaCarretas"
                        title="Pesquisar Carretas"
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarVeiculos
                              ref={veiculosRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              setLoading={setLoading}
                              habilitarPesquisaIntegrada={habilitarPesquisaIntegrada}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              centroCusto={centroCusto}
                            />
                          </form>
                        </div>
                      </Tab>

                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/ajudante');
                          setIsAjudanteTabFocused(true);
                        }}
                        eventKey="pesquisaFuncionario"
                        title="Pesquisar Ajudante/Funcionário (Não Motorista)"
                        onExit={() => setIsAjudanteTabFocused(false)}
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form>
                            <ContainerPesquisarAjudante
                              ref={ajudanteRef}
                              habilitarPesquisaVigente={habilitarPesquisaVigente}
                              setLoading={setLoading}
                              flagUtilizaCentroCusto={flagUtilizaCentroCusto}
                              centroCusto={centroCusto}
                            />
                          </form>
                        </div>
                      </Tab>
                    </Tabs>
                    <hr />
                    <Form.Row className="container-buttons justify-content-end">
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-cancelar"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Cancelar
                        </Button>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-enviar"
                          onClick={(event: any) => {
                            if (canInsert) {
                              handleClick(event);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        >
                          Confirmar
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
