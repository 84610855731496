/*eslint-disable*/
import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BinEdit from './pages/BauIntercambiavel/BinEdit';
import Home from './pages/Home';
import PrivateRoute from './components/Routes/PrivateRoute';
import Veiculo from './pages/Veiculo';
import VeiDetalhes from './pages/Veiculo/Form/index';
import Rotas from './pages/Rotas';
import CadastrarRota from './pages/CadastrarRota';
import CadastroVeiculo from './pages/Veiculo/CadastroVeiculo/index';
import AvisosImportantes from './pages/Monitoramento/AvisosImportantes/AvisosImportantes';
import CadastroBau from './pages/BauIntercambiavel/BinAdd';
import BauIntercambiavel from './pages/BauIntercambiavel';
import CadastroSat from './pages/CadastroSat/CadastroSat';
import EditarRotas from './pages/EditarRotas';
import SolicitacaoMonitoramentoCadastro from './pages/SolicitacaoMonitoramentoCadastro';
import Ajudantes from './pages/Ajudantes';
import AjudanteCadastro from './pages/AjudanteCadastro';
import AjudanteEditar from './pages/AjudanteEditar';
import CadastroUsuarioGerenciador from './pages/CadastroUsuarioGerenciador';
import UsuariosGerenciadores from './pages/UsuariosGerenciadores';
import AtualizacaoAvisos from './pages/AvisoOperacional/AtualizacaoAvisos';
import CadastroAvisos from './pages/AvisoOperacional/CadastroAvisos';
import ListagemCondutor from './pages/Condutor/ListagemCondutor';
import ListagemAvisos from './pages/AvisoOperacional/ListagemAvisos';
import AtualizarCondutor from './pages/Condutor/AtualizacaoCondutor';
import CadastroCondutor from './pages/Condutor/CadastroCondutor';
import AtualizarAvisosCliente from './pages/AvisoClientes/AtualizacaoAvisosCliente';
import CadastroAvisosCliente from './pages/AvisoClientes/CadastroAvisosCliente';
import ListagemAvisosCliente from './pages/AvisoClientes/ListagemAvisosCliente';
import Notificacao from './pages/AvisoClientes/Notificacao';
import GridPortaria from './pages/Portaria';
import ListagemChecklistEmbarcador from './pages/ChecklistEmbarcador';
import ListagemUsuariosCorretoras from './pages/Corretoras/GridUsuarios';
import CadastroUsuariosCorretoras from './pages/Corretoras/CadastroUsuario';
import AtualizarUsuariosCorretoras from './pages/Corretoras/AtualizacaoUsuario';
import CadastroUsuariosSeguradoras from './pages/Seguradoras/CadastroUsuario';
import ListagemUsuariosSeguradoras from './pages/Seguradoras/GridUsuarios';
import AtualizarUsuariosSeguradoras from './pages/Seguradoras/AtualizacaoUsuario';
import LancamentoNCTransportador from './pages/LancamentoNaoConformidade/LancarParaTransportador';
import LogOut from './pages/Logout';
import TempoParado from './pages/TempoParado';
import AnalisePerfilGrid from './pages/AnalisePerfil/Grid';
import EditarAnalisePerfil from './pages/AnalisePerfil/EditarAnalise';
import PesquisaCadastral from './pages/AnalisePerfil/PesquisaCadastral';
import AtualizacaoTecnologias from './pages/Tecnologias';
import CadastroParada from './pages/CadastroParada';
import EditarParada from './pages/EditarParada';
import IniciarSMPendente from './pages/IniciarSMPendente';
import EditarSMEmAndamento from './pages/EditarSMEmAndamento';
import { ServicoSmpt } from './pages/ParametrosCadastroServicoSmpt';
import { ParametrosSistema } from './pages/ParametrosSistema';
import { ParametrosIntegracao } from './pages/ParametrosIntegracao';
import { ParametrosAutomatizacao } from './pages/ParametrosAutomatizacao';
import { Reciclagem } from './pages/Reciclagem';
import { FaturamentoFrotaPage } from './pages/FaturamentoFrota';
import ProgressaoViagens from './pages/ProgressaoViagens/ProgressaoViagens';
import Mapa from './pages/Mapa/Mapa';
import EditarUsuarioGerenciador from './pages/EditarUsuarioGerenciador';
import AssociarClientes from './pages/AssociarClientes/AssociarClientes';
import CadastroUsuarioCliente from './pages/CadastroUsuarioCliente';
import UsuariosClientes from './pages/UsuariosClientes';
import EditarUsuarioCliente from './pages/EditarUsuarioCliente';
import NaoConformidades from './pages/NaoConformidades';
import CadastroNaoConformidade from './pages/CadastroNaoConformidade';
import FormularioConsultaNaoConformidade from './pages/NaoConformidades/ConsultaNaoConformidades/FormularioConsultaNaoConformidades';
import GridConsultaNaoConformidades from './pages/NaoConformidades/ConsultaNaoConformidades/GridConsultaNaoConformidades';
import EditarNaoConformidade from './pages/EditarNaoConformidade';
import GridCarretas from './pages/Carretas';
import VeiculosRastreamento from './pages/VeiculosRastreamento/VeiculosRastreamento';
import BlackListSeguradoras from './pages/BlackListSeguradoras';
import CadastroBlackListSeguradora from './pages/CadastroBlackListSeguradora';
import FaturamentoGerenciador from './pages/FaturamentoGerenciador';
import { EnviarPesquisa } from './pages/EnviarPesquisa';
import { HistoricoPesquisas } from './pages/Pesquisa/HistoricoPesquisas';
import GridReciclagem from './pages/GridReciclagem';
import RequisicaoAPI from './pages/RequisicaoAPI/RequisicaoAPI';
import HistoricoPosicoes from './pages/HistoricoPosicoes/HistoricoPosicoes';
import AcompanhamentoRemessa from './pages/AcompanhamentoRemessa/AcompanhamentoRemessas';
import AgendarViagens from './pages/AgendarViagens';
import ConfirmaPreSM from './pages/ConfirmaPreSM/ConfirmaPreSM';
import CancelamentoMonitoramento from './pages/CancelamentoMonitoramento/CancelamentoMonitoramento';
import ConsultarPesquisasLancadas from './pages/ConsultarPesquisasLancadas/ConsultarPesquisasLancadas';
import ConsultarSMLancadas from './pages/ConsultarSMLancadas/ConsultarSMLancadas';
import ListagemSMLancadas from './pages/ListagemSMLancadas/ListagemSMLancadas';
import FinalizarSMEmAndamento from './pages/FinalizarSMEmAndamento';
import VisualizarSMCancelada from './pages/VisualizarSMCancelada';
import EditarSMPendente from './pages/EditarSMPendente';
import CadastroPontos from './pages/Pontos/CadastroPontos';
import Paradas from './pages/Paradas';
import Pontos from './pages/Pontos/Pontos';
import EditarPontos from './pages/Pontos/EditarPontos';
import GridPreAgendamentoSM from './pages/AgendarViagens/GridPreAgendamentoSM';
import { DetalhePesquisa } from './pages/Pesquisa/DetalhePesquisa';
import CadastroSatFromMenu from './pages/CadastroSatFromMenu';
import NaoPossuiServico from './pages/NaoPossuiServico';
import ClienteGrid from './pages/Cliente/ClienteGrid';
import ClienteCadastro from './pages/Cliente/ClienteCadastro';
import ListagemChecklist from './pages/Checklist/ListagemChecklist';
import CadastroChecklist from './pages/Checklist/CadastroChecklist';
import EmbarcadorGrid from './pages/Embarcador/EmbarcadorGrid';
import EmbarcadorAdd from './pages/Embarcador/EmbarcadorAdd';
import CadastroCorretora from './pages/CadastroCorretora';
import CadastroSeguradora from './pages/CadastroSeguradora';
import ConsultarAjudante from './pages/ConsultarAjudantes/ConsultarAjudante';
import ConsultarCondutor from './pages/ConsultarCondutor/ConsultarCondutor';
import ConsultarProprietario from './pages/ConsultarProprietario/ConsultarProprietario';
import EditarProprietario from './pages/Proprietario/EditarProprietario';
import ListagemProprietario from './pages/Proprietario/ListagemProprietario';
import GridSat from './pages/GridSat/GridSat';
import VisualizarSat from './pages/GridSat/VisualizarSat';
import ConsultarVeiculo from './pages/ConsultarVeiculo/ConsultarVeiculo';
import ListagemConsultaVeiculo from './pages/ConsultarVeiculo/ListagemConsultarVeiculo';
import ConsultarCarreta from './pages/ConsultarCarreta/ConsultarCarreta';
import ListagemConsultaCarreta from './pages/ConsultarCarreta/ListagemConsultaCarreta';
import ListagemConsultaAjudante from './pages/ConsultarAjudantes/ListagemConsultaAjudante';
import ListagemConsultaCondutor from './pages/ConsultarCondutor/ListagemConsultaCondutor';
import ListagemConsultaProprietario from './pages/ConsultarProprietario/ListagemConsultaProprietario';
import ResumoSat from './pages/ResumoSat/ResumoSat';
import ListagemResumoSat from './pages/ResumoSat/ListagemResumoSat';
import ResumoChecklist from './pages/ResumoChecklistDetalhado/ResumoChecklist';
import GridPesquisaCadastral from './pages/AnalisePerfil/PesquisaCadastral/GridPesquisaCadastral';
import ConsultarCorretora from './pages/ConsultarCorretora';
import ConsultarSeguradora from './pages/ConsultarSeguradora';
import ConsultarMarca from './pages/GridEditavelMarca';
import ConsultarCor from './pages/GridEditavelCor';
import ConsultarTiposComunicacao from './pages/GridEditavelTipoComunicacao';
import ListagemDispositivos from './pages/Dispositivos/List';
import Xml from './pages/Xml';

import { LSC } from './pages/LSC';
import FormDispositivo from './pages/Dispositivos/Form';
import ListagemNaoConformidades from './pages/NaoConformidadeAdmin/List';
import FormNaoConformidade from './pages/NaoConformidadeAdmin/Form';
import ListagemGrupoMacroVeiculos from './pages/GrupoMacroVeiculos/List';
import ListagemGrupoVeiculos from './pages/GrupoVeiculo/List';
import FormGrupoVeiculo from './pages/GrupoVeiculo/Form';
import FormTiposDeVeiculos from './pages/TiposDeVeiculos/FormTiposDeVeiculos';
import ListagemTiposDeVeiculos from './pages/TiposDeVeiculos/ListagemTiposDeVeiculos';
import ListagemFaixasCubagem from './pages/FaixasCubagem/List';
import FormFaixasCubagem from './pages/FaixasCubagem/Form';
import ListagemSazonalidadeCarga from './pages/SazonalidadeCarga/List';
import FormSazonalidadeCarga from './pages/SazonalidadeCarga/Form';
import ListagemCapacidadeCarga from './pages/CapacidadeCarga/List';
import FormCapacidadeCarga from './pages/CapacidadeCarga/Form';
import ListagemRankingAjudanteFuncionario from './pages/RankingAjudanteFuncionario/List';
import FormRankingAjudanteFuncionario from './pages/RankingAjudanteFuncionario/Form';
import ListagemRankingCondutor from './pages/RankingCondutor/List';
import FormRankingCondutor from './pages/RankingCondutor/Form';
import ListagemRankingVeiculo from './pages/RankingVeiculo/List';
import FormRankingVeiculo from './pages/RankingVeiculo/Form';
import ConsultarCD from './pages/GridEditavelCD';
import ConsultarRegional from './pages/GridEditavelRegional';
import ListagemAnalistaPerfil from './pages/AnalistaPerfil/List';
import FormAnalistaPerfil from './pages/AnalistaPerfil/Form';
import ListagemTecnologiaRastreamento from './pages/TecnologiaAdmin/List';
import FormTecnologiaRastreamento from './pages/TecnologiaAdmin/Form';
import Motivos from './pages/Motivos';
import './global/styles.css';
import { PrevencaoAcidentes } from './pages/PrevencaoAcidentes';
import { ConsultarPerfisUsuarios } from './pages/ConsultarPerfisUsuarios';
import { CadastroPerfisUsuarios } from './pages/CadastroPerfisUsuarios';
import { CadastroPerfisUsuariosGr } from './pages/CadastroPerfisUsuariosGr';

import VelocidadePorVeiculo from './pages/Veiculo/VelocidadePorVeiculo';
import PerfisUsuariosClientes from './pages/PerfisUsuariosClientes';
import PerfisUsuariosClientesGr from './pages/PerfisUsuariosClientesGr';

import TemperaturaPorVeiculo from './pages/Veiculo/TemperaturaPorVeiculo';
import AcessoNegado from './components/AcessoNegado';
import { Cookies } from 'react-cookie';
import { PaginaTeste } from './pages/PaginaTeste';
import { Sm } from './components/SM';
import { CarregandoPermissoes } from './pages/CarregandoPermissoes';
import { SmsEnviados } from './pages/SmsEnviados/List';
import { PainelOperacional } from './pages/PainelOperacional';
import { ParametrosJornada } from './pages/ParametrosJornada';
import { ImportarPreSm } from './pages/ImportarPreSm';
import { MacrosJornada } from './pages/MacrosJornada/Form';
import { FiltroJornada } from './pages/MacrosJornada/Filter';
import { MacrosJornadaEditar } from './pages/MacrosJornada/Edicao';
import { ValidacaoCnh } from './pages/ValidacaoCnh/List';
import { ValidacaoCnhCadastro } from './pages/ValidacaoCnh/Form';
import { Gerentes } from './pages/Gerentes/List';
import { GerentesCadastro } from './pages/Gerentes/Form';
import { ParametrosLogs } from './pages/ParametrosLogs';
import { PERMISSION } from './constants/Permissionamento';
import { PocTeste } from './pages/AAPOC-TESTE';
import ListagemMarca from './pages/Marca/List';
import FormMarca from './pages/Marca/Form';
import ConsultarPesquisasTratadas from './pages/ConsultarPesquisasTratadas/ConsultarPesquisasTratadas';
import FiltroChecklist from './pages/ResumoChecklist';
import FiltroChecklistDetalhado from './pages/ResumoChecklistDetalhado';
import ListagemDescricaoCarga from './pages/DescricaoCarga/List';
import FormDescricaoCarga from './pages/DescricaoCarga/Form';
import { ParametrosLogsXml } from './pages/ParametrosLogsXml';

const RoutesSideBar: FC = () => {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;

  const cookies = new Cookies();

  return (
    <div>
      <Switch>
        <PrivateRoute
          path="/home/:page?"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={Home}
        >
          <Home />
        </PrivateRoute>
        {/* ROTAS PESQUISA */}
        <PrivateRoute
          path="/enviar-pesquisa/:tabAtual/:id?"
          servicoContratado="cscNuCadastroConsulta"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.ANALISE_DE_PERFIL.ENVIAR_PESQUISA] }]}
          exact
        >
          <EnviarPesquisa />
        </PrivateRoute>
        <PrivateRoute
          path="/historico-pesquisas"
          servicoContratado="cscNuCadastroConsulta"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.ANALISE_DE_PERFIL.HISTORICO_DE_PESQUISA] }]}
          exact
        >
          <HistoricoPesquisas />
        </PrivateRoute>
        <PrivateRoute path="/listar/sms-enviados/:page" allowedRoutes={['user']} exact>
          <SmsEnviados />
        </PrivateRoute>
        <PrivateRoute
          path="/detalhe-pesquisa/:id/:idCliente"
          servicoContratado="cscNuCadastroConsulta"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.ANALISE_DE_PERFIL.HISTORICO_DE_PESQUISA],
              ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL],
            },
          ]}
          exact
        >
          <DetalhePesquisa />
        </PrivateRoute>
        <PrivateRoute
          path="/progressao-viagens"
          allowedRoutes={['admin', 'user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.PROGRESSAO_DE_VIAGEM] }]}
          exact
        >
          <ProgressaoViagens />
        </PrivateRoute>
        <PrivateRoute
          path="/mapa"
          servicoContratado="cscNuMapaRastreamento"
          allowedRoutes={['user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.MAPA],
              EMBARCADOR: [EMBARCADOR.CONTROLE_LOGISTICO.MAPA],
            },
          ]}
          exact
        >
          <Mapa />
        </PrivateRoute>
        <PrivateRoute
          path="/mapa-operacao"
          servicoContratado="cscNuMapaRastreamento"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CONTROLE_LOGISTICO.MAPA] }]}
        >
          <Mapa />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/ajudantes"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.AJUDANTE] }]}
          exact
        >
          <ConsultarAjudante />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/condutores"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CONDUTOR] }]}
          exact
        >
          <ConsultarCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/veiculos"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.VEICULO] }]}
          exact
        >
          <ConsultarVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/veiculos"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.VEICULO] }]}
          exact
        >
          <ListagemConsultaVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/carretas"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CARRETA] }]}
          exact
        >
          <ConsultarCarreta />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/carretas"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CARRETA] }]}
          exact
        >
          <ListagemConsultaCarreta />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/ajudantes"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.AJUDANTE] }]}
          exact
        >
          <ListagemConsultaAjudante />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/condutores"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CONDUTOR] }]}
          exact
        >
          <ListagemConsultaCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/proprietarios"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.PROPRIETARIO] }]}
          exact
        >
          <ListagemConsultaProprietario />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/proprietarios"
          permission={[{ ADMIN: [ADMIN.CADASTRO.PROPRIETARIO] }]}
          allowedRoutes={['admin']}
          exact
        >
          <ConsultarProprietario />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-proprietario/:id"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PROPRIETARIO],
              ADMIN: [ADMIN.CADASTRO.PROPRIETARIO],
            },
          ]}
        >
          <EditarProprietario />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-proprietario"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PROPRIETARIO],
              ADMIN: [ADMIN.CADASTRO.PROPRIETARIO],
            },
          ]}
          exact
        >
          <EditarProprietario />
        </PrivateRoute>
        {/* ROTAS USER */}
        <PrivateRoute
          path="/cliente/listagem/proprietario/:page?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PROPRIETARIO] }]}
        >
          <ListagemProprietario />
        </PrivateRoute>
        {/* ROTA CLIENTE FATURAMENTO/FROTA */}
        <PrivateRoute
          path="/cliente/FaturamentoFrota/:page?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.FATURAMENTO_FROTA] }]}
          exact
        >
          <FaturamentoFrotaPage />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-condutor/:fromSMCadastro?"
          component={CadastroCondutor}
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CONDUTOR] }]}
        >
          <CadastroCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/condutores/listar/:page?"
          component={ListagemCondutor}
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CONDUTOR] }]}
        >
          <ListagemCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/condutores/editar/:id"
          component={AtualizarCondutor}
          allowedRoutes={['user', 'admin']}
          permission={[
            { ADMIN: [ADMIN.CADASTRO.CONDUTOR], TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CONDUTOR] },
          ]}
        >
          <AtualizarCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/ajudantes/listar/:page"
          component={Ajudantes}
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.AJUDANTE] }]}
        >
          <Ajudantes />
        </PrivateRoute>
        <PrivateRoute
          path="/ajudantes/editar/:id"
          allowedRoutes={['user', 'admin']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.AJUDANTE], ADMIN: [ADMIN.CADASTRO.AJUDANTE] },
          ]}
        >
          <AjudanteEditar />
        </PrivateRoute>
        <PrivateRoute
          path="/ajudantes/cadastrar"
          allowedRoutes={['user', 'admin']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.AJUDANTE], ADMIN: [ADMIN.CADASTRO.AJUDANTE] },
          ]}
        >
          <AjudanteCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro/veiculo"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] }]}
          exact
        >
          <Veiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/controle-velocidade-por-veiculo"
          servicoContratado="controleVelocidade"
          allowedRoutes={['user']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_VELOCIDADE] },
          ]}
          exact
        >
          <VelocidadePorVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/controle-temperatura-por-veiculo"
          servicoContratado="controleTemperatura"
          allowedRoutes={['user']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_TEMPERATURA] },
          ]}
          exact
        >
          <TemperaturaPorVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/atualizarveiculo/:id"
          allowedRoutes={['user', 'admin']}
          permission={[
            { ADMIN: [ADMIN.CADASTRO.VEICULO], TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] },
          ]}
        >
          <CadastroVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/veicadastro/:fromSMCadastro?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] }]}
        >
          <CadastroVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/veidetalhes/:id"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] }]}
        >
          <VeiDetalhes />
        </PrivateRoute>
        {/* <PrivateRoute path="/cliente/cadastro/carreta" allowedRoutes={['user']} exact>
          <CadCarreta />
        </PrivateRoute>
        <PrivateRoute path="/cliente/cadastro/cadastrarCarreta" allowedRoutes={['user']} exact>
          <CadastrarCarreta />
        </PrivateRoute> */}
        <PrivateRoute
          path="/bau-intercambiavel/:page?"
          allowedRoutes={['user']}
          component={BauIntercambiavel}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.BAU] }]}
        >
          <BauIntercambiavel />
        </PrivateRoute>
        <PrivateRoute
          path="/bau-intercambiavel-add/:fromSMCadastro?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.BAU] }]}
        >
          <CadastroBau />
        </PrivateRoute>
        <PrivateRoute
          path="/editar/bau-intercambiavel/:id"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.BAU] }]}
        >
          <BinEdit />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-sazonalidade-da-carga/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA] }]}
        >
          <ListagemSazonalidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-sazonalidade-da-carga"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA] }]}
          exact
        >
          <FormSazonalidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-sazonalidade-da-carga/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA] }]}
        >
          <FormSazonalidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/capacidade-de-carga/listar/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA] }]}
        >
          <ListagemCapacidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/capacidade-de-carga/cadastrar"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA] }]}
          exact
        >
          <FormCapacidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/capacidade-de-carga/editar/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA] }]}
        >
          <FormCapacidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/ajudante-funcionario/listar/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE] }]}
        >
          <ListagemRankingAjudanteFuncionario />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/ajudante-funcionario/cadastrar"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE] }]}
          exact
        >
          <FormRankingAjudanteFuncionario />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/ajudante-funcionario/editar/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE] }]}
        >
          <FormRankingAjudanteFuncionario />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/condutor/listar/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR] }]}
        >
          <ListagemRankingCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/condutor/cadastrar/"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR] }]}
          exact
        >
          <FormRankingCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/condutor/editar/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR] }]}
        >
          <FormRankingCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/veiculo/listar/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO] }]}
        >
          <ListagemRankingVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/veiculo/cadastrar/"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO] }]}
          exact
        >
          <FormRankingVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/veiculo/editar/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO] }]}
        >
          <FormRankingVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/analistas-de-perfil/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.ANALISTA_PERFIL] }]}
        >
          <ListagemAnalistaPerfil />
        </PrivateRoute>

        <PrivateRoute
          path="/analistas-de-perfil/cadastrar/"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.ANALISTA_PERFIL] }]}
          exact
        >
          <FormAnalistaPerfil />
        </PrivateRoute>

        <PrivateRoute
          path="/analistas-de-perfil/editar/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.ANALISTA_PERFIL] }]}
        >
          <FormAnalistaPerfil />
        </PrivateRoute>
        {/* <PrivateRoute
          path="/cliente/ranking/ajudantefuncionarionmotor"
          allowedRoutes={['user']}
          exact
        >
          <CadAjudanteFuncNMotor />
        </PrivateRoute> */}
        <PrivateRoute
          path="/rota/:page?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
        >
          <Rotas />
        </PrivateRoute>

        <PrivateRoute
          path="/consultar-sm-lancadas"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
              EMBARCADOR: [EMBARCADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADAS],
              ADMIN: [ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
            },
          ]}
          exact
        >
          <ConsultarSMLancadas />
        </PrivateRoute>

        <PrivateRoute
          path="/listagem-sm-lancadas/:page?"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
              EMBARCADOR: [EMBARCADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADAS],
              ADMIN: [ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
            },
          ]}
        >
          <ListagemSMLancadas />
        </PrivateRoute>

        <PrivateRoute
          path="/rota-editar-cadastrar/:id"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
        >
          <EditarRotas />
        </PrivateRoute>

        <PrivateRoute
          path="/rota-editar-cadastrar"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
          exact
        >
          <EditarRotas />
        </PrivateRoute>

        <PrivateRoute
          path="/rotaeditarparada/:id"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
        >
          <Paradas />
        </PrivateRoute>

        <PrivateRoute
          path="/rotacadastro/:fromSMCadastro?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
        >
          <CadastrarRota />
        </PrivateRoute>

        <PrivateRoute
          path="/paradacadastro/:id"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
        >
          <CadastroParada />
        </PrivateRoute>

        <PrivateRoute
          path="/paradaeditar/:id"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }]}
        >
          <EditarParada />
        </PrivateRoute>
        <PrivateRoute
          path="/sm/:type/:id"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          permission={[
            {
              ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL, ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
            },
          ]}
        >
          <IniciarSMPendente />
        </PrivateRoute>
        <PrivateRoute
          path="/finalizar-sm-emandamento/:id"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          permission={[
            {
              ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL, ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
            },
          ]}
        >
          <FinalizarSMEmAndamento />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-sm-pendente/:id"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL, ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
            },
          ]}
        >
          <EditarSMPendente />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-sm-emandamento/:id?"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          permission={[
            {
              ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL, ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
            },
          ]}
        >
          <EditarSMEmAndamento />
        </PrivateRoute>
        <PrivateRoute
          path="/visualizar-sm-cancelada/:id?"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          permission={[
            {
              ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL, ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
            },
          ]}
        >
          <VisualizarSMCancelada />
        </PrivateRoute>
        <PrivateRoute
          path="/solicitacao-monitoramento-xml"
          servicoContratado="cscNuMonitoramentoVeiculos"
          allowedRoutes={['user']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.SOLICITACAO_MONITORAMENTO] },
          ]}
        >
          <Xml />
        </PrivateRoute>

        <PrivateRoute
          path="/solicitacao-monitoramento/:id?"
          servicoContratado="cscNuMonitoramentoVeiculos"
          allowedRoutes={['user']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.SOLICITACAO_MONITORAMENTO] },
          ]}
        >
          <SolicitacaoMonitoramentoCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/cancelamento-monitoramento/:page?"
          allowedRoutes={['user']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CANCELAMENTO_MONITORAMENTO] },
          ]}
        >
          <CancelamentoMonitoramento />
        </PrivateRoute>
        <PrivateRoute
          path="/confirmacao-presm/:page?"
          allowedRoutes={['user']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CONFIRMACAO_PRE_SM] },
          ]}
        >
          <ConfirmaPreSM />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/presm/agendar-viagens"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.OPERACIONAL.PRE_SM] }]}
          exact
        >
          <AgendarViagens />
        </PrivateRoute>
        <PrivateRoute
          path="/importar-pre-sm"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.OPERACIONAL.IMPORTAR_CSV] }]}
          exact
        >
          <ImportarPreSm />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/painel-operacional/:tab/:page"
          allowedRoutes={['user', 'admin']}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL] }]}
          exact
        >
          <PainelOperacional />
        </PrivateRoute>

        <PrivateRoute
          path="/cliente/operacional/cadastrosat"
          allowedRoutes={['admin']}
          permission={[
            {
              ADMIN: [
                ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA,
                ADMIN.CONSULTA_RELATORIO.RESUMO_SAT,
              ],
            },
          ]}
          exact
        >
          <CadastroSatFromMenu />
        </PrivateRoute>
        {/* FOI  */}
        <PrivateRoute
          path="/cliente/operacional/visualizarsat/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA] }]}
        >
          <VisualizarSat />
        </PrivateRoute>

        <PrivateRoute
          path="/checklist/filtro-checklist"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.CHECKLIST],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.CHECKLIST],
            },
          ]}
          exact
        >
          <FiltroChecklist />
        </PrivateRoute>

        <PrivateRoute
          path="/checklist/filtro-checklist-detalhado"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.CHECKLIST],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.CHECKLIST],
            },
          ]}
          exact
        >
          <FiltroChecklistDetalhado />
        </PrivateRoute>

        <PrivateRoute
          path="/checklist/resumo-checklist"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.CHECKLIST],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.CHECKLIST],
            },
          ]}
          exact
        >
          <ResumoChecklist />
        </PrivateRoute>

        <PrivateRoute
          path="/clientes/listar/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CLIENTE] }]}
        >
          <ClienteGrid />
        </PrivateRoute>

        <PrivateRoute
          path="/cadastrar-clientes/:activeKey"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CLIENTE] }]}
          exact
        >
          <ClienteCadastro />
        </PrivateRoute>

        <PrivateRoute
          path="/editar-cliente/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CLIENTE] }]}
        >
          <ClienteCadastro />
        </PrivateRoute>
        {/* Rota Reciclagem */}
        <PrivateRoute
          path="/cliente/operacional/Solicitacoes/Reciclagem"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.OPERACIONAL.RECICLAGEM] }]}
          exact
        >
          <Reciclagem />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/listar-reciclagem/:page?"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.OPERACIONAL.RECICLAGEM] }]}
        >
          <GridReciclagem />
        </PrivateRoute>
        {/* VERIFICAR TROCA DE MENU */}
        <PrivateRoute
          path="/listar/avisos-operacionais"
          allowedRoutes={['admin']}
          component={ListagemAvisos}
          permission={[{ ADMIN: [ADMIN.AVISO.OPERACIONAL] }]}
          exact
        >
          <ListagemAvisos />
        </PrivateRoute>
        <PrivateRoute
          path="/operacionais/novo"
          allowedRoutes={['admin']}
          component={CadastroAvisos}
          permission={[{ ADMIN: [ADMIN.AVISO.OPERACIONAL] }]}
          exact
        >
          <CadastroAvisos />
        </PrivateRoute>
        <PrivateRoute
          path="/operacionais/atualizar/:id"
          allowedRoutes={['admin']}
          component={AtualizacaoAvisos}
          permission={[{ ADMIN: [ADMIN.AVISO.OPERACIONAL] }]}
        >
          <AtualizacaoAvisos />
        </PrivateRoute>
        {/* AVISOS CLIENTES */}
        <PrivateRoute
          path="/avisos/clientes/atualizar/:id"
          allowedRoutes={['admin']}
          component={AtualizarAvisosCliente}
          permission={[{ ADMIN: [ADMIN.AVISO.CLIENTE] }]}
        >
          <AtualizarAvisosCliente />
        </PrivateRoute>
        <PrivateRoute
          path="/avisos/clientes/cadastro"
          allowedRoutes={['admin']}
          component={CadastroAvisosCliente}
          permission={[{ ADMIN: [ADMIN.AVISO.CLIENTE] }]}
        >
          <CadastroAvisosCliente />
        </PrivateRoute>
        <PrivateRoute path="/poc-teste" allowedRoutes={['user', 'admin']} component={PocTeste}>
          <PocTeste />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/avisos-clientes"
          allowedRoutes={['admin']}
          component={ListagemAvisosCliente}
          permission={[{ ADMIN: [ADMIN.AVISO.CLIENTE] }]}
        >
          <ListagemAvisosCliente />
        </PrivateRoute>
        <PrivateRoute path="/notificacao" allowedRoutes={['user']} component={Notificacao}>
          <Notificacao />
        </PrivateRoute>
        <PrivateRoute
          path="/carregando-permissoes"
          allowedRoutes={['user', 'admin']}
          component={CarregandoPermissoes}
        >
          <CarregandoPermissoes />
        </PrivateRoute>
        {/* */}
        {/* Rotas Portaria */}
        <PrivateRoute
          path="/portaria"
          allowedRoutes={['user', 'admin']}
          component={GridPortaria}
          permission={[{ EMBARCADOR: [EMBARCADOR.PORTARIA] }]}
        >
          <GridPortaria />
        </PrivateRoute>
        {/* */}
        {/* Rotas Expedição */}
        <PrivateRoute
          path="/embarcador/checklist"
          allowedRoutes={['user']}
          component={ListagemChecklistEmbarcador}
          permission={[{ EMBARCADOR: [EMBARCADOR.CHECKLIST] }]}
        >
          <ListagemChecklistEmbarcador />
        </PrivateRoute>
        {/* */}
        {/* Rotas Seguradoras */}
        <PrivateRoute
          path="/listar-usuarios-seguradoras/:page?"
          allowedRoutes={['seguradora', 'admin']}
          component={ListagemUsuariosSeguradoras}
          permission={[{ ADMIN: [ADMIN.USUARIO.SEGURADORA] }]}
        >
          <ListagemUsuariosSeguradoras />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-usuario-seguradoras/:id"
          allowedRoutes={['seguradora', 'admin']}
          component={CadastroUsuariosSeguradoras}
          permission={[{ ADMIN: [ADMIN.USUARIO.SEGURADORA] }]}
        >
          <CadastroUsuariosSeguradoras />
        </PrivateRoute>
        <PrivateRoute
          path="/atualizacao-usuario-seguradoras/:idUsu/:idSeguradora/:loginAntigo"
          allowedRoutes={['seguradora', 'admin']}
          component={AtualizarUsuariosSeguradoras}
          permission={[{ ADMIN: [ADMIN.USUARIO.SEGURADORA] }]}
        >
          <AtualizarUsuariosSeguradoras />
        </PrivateRoute>
        {/* */}
        {/* Rotas Corretoras */}
        <PrivateRoute
          path="/listar-usuarios-corretoras/:page?"
          allowedRoutes={['corretora', 'admin']}
          component={ListagemUsuariosCorretoras}
          permission={[{ ADMIN: [ADMIN.USUARIO.CORRETORA] }]}
        >
          <ListagemUsuariosCorretoras />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-usuario-corretoras/:idCorretora"
          allowedRoutes={['corretora', 'admin']}
          component={CadastroUsuariosCorretoras}
          permission={[{ ADMIN: [ADMIN.USUARIO.CORRETORA] }]}
        >
          <CadastroUsuariosCorretoras />
        </PrivateRoute>
        <PrivateRoute
          path="/atualizacao-usuario-corretoras/:idUsu/:idCorretora/:loginAntigo"
          allowedRoutes={['corretora', 'admin']}
          component={AtualizarUsuariosCorretoras}
          permission={[{ ADMIN: [ADMIN.USUARIO.CORRETORA] }]}
        >
          <AtualizarUsuariosCorretoras />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/macros-jornada/:page"
          allowedRoutes={['user']}
          component={MacrosJornada}
        >
          <MacrosJornada />
        </PrivateRoute>
        <PrivateRoute
          path="/editar/macros-jornada/:id"
          allowedRoutes={['user']}
          component={MacrosJornadaEditar}
        >
          <MacrosJornadaEditar />
        </PrivateRoute>
        <PrivateRoute
          path="/filtrar/macros-jornada/:page"
          allowedRoutes={['user']}
          component={MacrosJornada}
        >
          <FiltroJornada />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/motivos/:page"
          allowedRoutes={['admin']}
          component={Motivos}
          permission={[{ ADMIN: [ADMIN.CADASTRO.MOTIVO] }]}
        >
          <Motivos />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/gerentes/:page"
          allowedRoutes={['admin']}
          component={Gerentes}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GERENTE] }]}
        >
          <Gerentes />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/cadastrar/gerentes"
          allowedRoutes={['admin']}
          component={GerentesCadastro}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GERENTE] }]}
        >
          <GerentesCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/editar/gerentes/:id"
          allowedRoutes={['admin']}
          component={GerentesCadastro}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GERENTE] }]}
        >
          <GerentesCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/prevencao-de-acidentes"
          allowedRoutes={['user']}
          component={PrevencaoAcidentes}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.PREVENCAO_DE_ACIDENTE],
              EMBARCADOR: [EMBARCADOR.CONTROLE_LOGISTICO.PREVENCAO_ACIDENTE],
            },
          ]}
        >
          <PrevencaoAcidentes />
        </PrivateRoute>
        {/* Lançamento de não conformidade */}
        <PrivateRoute
          path="/lancar-nao-conformidade/:id/:idCliente/:empresa"
          allowedRoutes={['admin']}
          component={LancamentoNCTransportador}
        >
          <LancamentoNCTransportador />
        </PrivateRoute>
        <PrivateRoute path="/paginateste" allowedRoutes={['admin', 'user']} component={PaginaTeste}>
          <PaginaTeste />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/nao-conformidades/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.NAO_CONFORMIDADE] }]}
        >
          <ListagemNaoConformidades />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-nao-conformidade"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.NAO_CONFORMIDADE] }]}
        >
          <FormNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-nao-conformidade/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.NAO_CONFORMIDADE] }]}
        >
          <FormNaoConformidade />
        </PrivateRoute>
        <PrivateRoute path="/sm/:id?" allowedRoutes={['admin', 'user']} exact>
          <Sm />
        </PrivateRoute>
        {/* */}
        {/* Lançamento de não conformidade */}
        <PrivateRoute
          path="/nao-conformidade/consulta"
          exact
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={FormularioConsultaNaoConformidade}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.NAO_CONFORMIDADE],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.NAO_CONFORMIDADE],
            },
          ]}
        >
          <FormularioConsultaNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/nao-conformidade/consulta/grid"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={GridConsultaNaoConformidades}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.NAO_CONFORMIDADE],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.NAO_CONFORMIDADE],
            },
          ]}
        >
          <GridConsultaNaoConformidades />
        </PrivateRoute>
        {/* */}
        {/* Logout */}
        <PrivateRoute
          path="/logout"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={LogOut}
        >
          <LogOut />
        </PrivateRoute>
        <PrivateRoute
          path="/erroservico"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={NaoPossuiServico}
        >
          <NaoPossuiServico />
        </PrivateRoute>
        <PrivateRoute
          path="/acessonegado"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={NaoPossuiServico}
        >
          <AcessoNegado />
        </PrivateRoute>
        {/* */}
        {/* Tempo Parado */}
        <PrivateRoute
          path="/rastreamento/tempo-parado"
          allowedRoutes={['admin', 'user']}
          component={TempoParado}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.TEMPO_PARADO],
              TRANSPORTADOR: [
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RASTREAMENTO.TEMPO_PARADO,
              ],
            },
          ]}
        >
          <TempoParado />
        </PrivateRoute>
        {/* */}
        {/* Análise Perfil */}
        <PrivateRoute
          path="/analise-perfil/painel/:page"
          allowedRoutes={['admin']}
          component={AnalisePerfilGrid}
          permission={[
            { ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL] },
          ]}
        >
          <AnalisePerfilGrid />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/editar/:id?"
          allowedRoutes={['admin']}
          component={EditarAnalisePerfil}
          permission={[
            { ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL] },
          ]}
          exact
        >
          <EditarAnalisePerfil />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/pesquisa"
          allowedRoutes={['admin']}
          component={PesquisaCadastral}
          permission={[
            { ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL] },
          ]}
          exact
        >
          <PesquisaCadastral />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/pesquisa/:id"
          allowedRoutes={['admin']}
          component={PesquisaCadastral}
          permission={[
            { ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL] },
          ]}
        >
          <PesquisaCadastral />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/grid"
          allowedRoutes={['admin']}
          component={GridPesquisaCadastral}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO] }]}
          exact
        >
          <GridPesquisaCadastral />
        </PrivateRoute>
        {/* */}
        {/* Tecnologias */}
        <PrivateRoute
          path="/listar/historico-tecnologias/:page"
          allowedRoutes={['admin']}
          component={AtualizacaoTecnologias}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.TECNOLOGIA] }]}
        >
          <AtualizacaoTecnologias />
        </PrivateRoute>
        {/* Checklist */}
        <PrivateRoute
          path="/cliente/operacional/listar-checklist/:page?"
          allowedRoutes={['admin']}
          component={ListagemChecklist}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO] }]}
        >
          <ListagemChecklist />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/operacional/cadastro-checklist"
          allowedRoutes={['admin']}
          component={CadastroChecklist}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO] }]}
        >
          <CadastroChecklist />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/operacional/atualizar-checklist/:id"
          allowedRoutes={['admin']}
          component={CadastroChecklist}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO] }]}
        >
          <CadastroChecklist />
        </PrivateRoute>
        {/* */}
        {/* Carretas */}
        <PrivateRoute
          path="/cadastro/carretas/:page?"
          allowedRoutes={['user']}
          component={GridCarretas}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CARRETA] }]}
          exact
        >
          <GridCarretas />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro/carretas/cadastrar/:isCarreta?"
          allowedRoutes={['user']}
          permission={[{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CARRETA] }]}
        >
          <CadastroVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro/carretas/atualizar/:isCarreta?/:id?"
          allowedRoutes={['user', 'admin']}
          permission={[
            { TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CARRETA], ADMIN: [ADMIN.CADASTRO.CARRETA] },
          ]}
        >
          <CadastroVeiculo />
        </PrivateRoute>
        {/* */}
        <Route path="/monitoramento/cadastro-sat/:id?/:register?">
          <CadastroSat />
        </Route>
        <Route path="/avisos" component={AvisosImportantes} exact>
          <AvisosImportantes />
        </Route>
        {/* VERIFICAR TROCA DE MENU */}
        {/* ROTAS ADMIN */}
        <PrivateRoute
          path="/associar-clientes/:user?/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.USUARIO.ASSOCIAR_CLIENTE] }]}
        >
          <AssociarClientes />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-pontos"
          servicoContratado="cscNuRotaPorPontos"
          allowedRoutes={['user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PONTO],
              EMBARCADOR: [EMBARCADOR.CADASTRO.PONTO],
            },
          ]}
        >
          <CadastroPontos />
        </PrivateRoute>
        <PrivateRoute
          path="/pontos/:page?"
          servicoContratado="cscNuRotaPorPontos"
          allowedRoutes={['user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PONTO],
              EMBARCADOR: [EMBARCADOR.CADASTRO.PONTO],
            },
          ]}
        >
          <Pontos />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-pontos/:id"
          servicoContratado="cscNuRotaPorPontos"
          allowedRoutes={['user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PONTO],
              EMBARCADOR: [EMBARCADOR.CADASTRO.PONTO],
            },
          ]}
        >
          <EditarPontos />
        </PrivateRoute>
        <PrivateRoute
          path="/pre-agendamento-viagens/:page?"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.OPERACIONAL.PRE_SM] }]}
        >
          <GridPreAgendamentoSM />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/tipos-de-comunicacao/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TIPO_COMUNICACAO] }]}
        >
          <ConsultarTiposComunicacao />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/tecnologias-de-rastreamento/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
        >
          <ListagemTecnologiaRastreamento />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/editar-tecnologia-de-rastreamento/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
        >
          <FormTecnologiaRastreamento />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/cadastrar-tecnologia-de-rastreamento"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
          exact
        >
          <FormTecnologiaRastreamento />
        </PrivateRoute>

        <PrivateRoute
          path="/listar/marcas/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
        >
          <ListagemMarca />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/cadastrar-marca"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
          exact
        >
          <FormMarca />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/listar/descricao-carga/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
        >
          <ListagemDescricaoCarga />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/cadastrar-descricao-carga"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
          exact
        >
          <FormDescricaoCarga />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/editar-descricao-carga/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TECNOLOGIA] }]}
        >
          <FormDescricaoCarga />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/listar/validacao-cnh/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.VALIDACAO_CNH] }]}
          exact
        >
          <ValidacaoCnh />
        </PrivateRoute>
        <PrivateRoute path="/admin/cadastrar/validacao-cnh" allowedRoutes={['admin']} exact>
          <ValidacaoCnhCadastro />
        </PrivateRoute>

        <PrivateRoute path="/admin/editar/validacao-cnh/:id" allowedRoutes={['admin']} exact>
          <ValidacaoCnhCadastro />
        </PrivateRoute>

        <PrivateRoute
          path="/listar/dispositivos/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.DISPOSITIVO] }]}
        >
          <ListagemDispositivos />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-dispositivo/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.DISPOSITIVO] }]}
        >
          <FormDispositivo />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-dispositivo"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.DISPOSITIVO] }]}
        >
          <FormDispositivo />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/grupo-macro-de-veiculos"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GRUPO_MACRO_VEICULO] }]}
        >
          <ListagemGrupoMacroVeiculos />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/grupos-de-veiculos/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO] }]}
        >
          <ListagemGrupoVeiculos />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-grupos-de-veiculos"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO] }]}
        >
          <FormGrupoVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-grupos-de-veiculos/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO] }]}
        >
          <FormGrupoVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/veiculo-marcas/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.MARCA] }]}
        >
          <ConsultarMarca />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/cor/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.COR] }]}
        >
          <ConsultarCor />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/rastreamento/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.RASTREAMENTO] }]}
        >
          <VeiculosRastreamento />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/cadastro-corretora"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CORRETORA] }]}
        >
          <CadastroCorretora />
        </PrivateRoute>
        <PrivateRoute
          path="/edicao-corretora/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CORRETORA] }]}
        >
          <CadastroCorretora />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/corretoras"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.CORRETORA] }]}
        >
          <ConsultarCorretora />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/cadastro-seguradora"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.SEGURADORA] }]}
        >
          <CadastroSeguradora />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/tipos-de-veiculo/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TIPO_VEICULO] }]}
        >
          <ListagemTiposDeVeiculos />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tipo-de-veiculo/cadastrar"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TIPO_VEICULO] }]}
        >
          <FormTiposDeVeiculos />
        </PrivateRoute>
        <PrivateRoute
          path="/tipo-de-veiculo/editar/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.TIPO_VEICULO] }]}
        >
          <FormTiposDeVeiculos />
        </PrivateRoute>
        <PrivateRoute
          path="/edicao-seguradora/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.SEGURADORA] }]}
        >
          <CadastroSeguradora />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/seguradoras"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.SEGURADORA] }]}
        >
          <ConsultarSeguradora />
        </PrivateRoute>
        <PrivateRoute path="/cadastro-usuario-gerenciador" allowedRoutes={['admin']} exact>
          <CadastroUsuarioGerenciador />
        </PrivateRoute>
        <PrivateRoute
          path="/consultar-pesquisas-lancadas/:request?/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL] }]}
        >
          <ConsultarPesquisasLancadas />
        </PrivateRoute>
        <PrivateRoute
          path="/consultar-pesquisas-tratadas/:request?/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL] }]}
        >
          <ConsultarPesquisasTratadas />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-usuarios-gerenciadores/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.USUARIO.GERENCIADOR] }]}
        >
          <UsuariosGerenciadores />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-usuario-cliente/:id"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO],
              EMBARCADOR: [EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO],
              ADMIN: [ADMIN.USUARIO.CLIENTE],
            },
          ]}
        >
          <CadastroUsuarioCliente />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-perfis-usuarios/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
        >
          <PerfisUsuariosClientes />
        </PrivateRoute>

        <PrivateRoute
          path="/listar-perfis-usuarios-gr/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
        >
          <PerfisUsuariosClientesGr />
        </PrivateRoute>

        {/* <PrivateRoute
          path="/listar-usuarios-clientes/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[{
            {
              embarcador: EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
              transportador: TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
              admin: ADMIN.USUARIO.CLIENTE
            }
          ]}
        >
          <UsuariosClientes />
        </PrivateRoute> */}

        <PrivateRoute
          path="/listar-usuarios-clientes/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              EMBARCADOR: [EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO],
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO],
              ADMIN: [ADMIN.USUARIO.CLIENTE],
            },
          ]}
        >
          <UsuariosClientes />
        </PrivateRoute>

        <PrivateRoute
          path="/cadastro-perfis-usuarios/:id?"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
          exact
        >
          <CadastroPerfisUsuarios />
        </PrivateRoute>

        <PrivateRoute
          path="/cadastro-perfis-usuarios-gr/:id?"
          allowedRoutes={['admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
          exact
        >
          <CadastroPerfisUsuariosGr />
        </PrivateRoute>

        <PrivateRoute
          path="/edicao-perfis-usuarios-gr/:id?"
          allowedRoutes={['admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
          exact
        >
          <CadastroPerfisUsuariosGr />
        </PrivateRoute>

        <PrivateRoute
          path="/edicao-perfis-usuarios/:id"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
        >
          <CadastroPerfisUsuarios />
        </PrivateRoute>
        <PrivateRoute
          path="/perfis-usuarios/listar/:page"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
              EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
              ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
            },
          ]}
        >
          <ConsultarPerfisUsuarios />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-usuario-gerenciador/:id"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.USUARIO.GERENCIADOR] }]}
        >
          <EditarUsuarioGerenciador />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-usuario-cliente/:id/:cliente"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO],
              ADMIN: [ADMIN.USUARIO.CLIENTE],
              EMBARCADOR: [EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO],
            },
          ]}
          exact
        >
          <EditarUsuarioCliente />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-naoconformidades/:page?"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.NAO_CONFORMIDADE] }]}
        >
          <NaoConformidades />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-faixas-de-cubagem/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM] }]}
        >
          <ListagemFaixasCubagem />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-faixas-de-cubagem"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM] }]}
          exact
        >
          <FormFaixasCubagem />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-faixas-de-cubagem/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM] }]}
        >
          <FormFaixasCubagem />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-naoconformidades"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.NAO_CONFORMIDADE] }]}
          exact
        >
          <CadastroNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-naoconformidades/:id"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.NAO_CONFORMIDADE] }]}
        >
          <EditarNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/blacklist-seguradora/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.BLACKLIST_SEGURADORA] }]}
        >
          <BlackListSeguradoras />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-blacklistseguradora"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CADASTRO.BLACKLIST_SEGURADORA] }]}
          exact
        >
          <CadastroBlackListSeguradora />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/faturamento-frota/:page?"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.FATURAMENTO_FROTA] }]}
        >
          <FaturamentoGerenciador />
        </PrivateRoute>
        <PrivateRoute
          path="/painel-lsc/:page"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.PAINEL_LSC] }]}
        >
          <LSC />
        </PrivateRoute>
        <PrivateRoute
          path="/rastreamento/requisicao-api"
          allowedRoutes={['admin']}
          permission={[{ ADMIN: [ADMIN.CONSULTA_RELATORIO.REQUISICAO_API] }]}
          exact
        >
          <RequisicaoAPI />
        </PrivateRoute>
        <PrivateRoute
          path="/rastreamento/historico-posicoes/:page"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.HISTORICO_POSICAO],
              TRANSPORTADOR: [
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RASTREAMENTO.HISTORICO_POSICAO,
              ],
            },
          ]}
        >
          <HistoricoPosicoes />
        </PrivateRoute>
        <PrivateRoute
          path="/consultas-relatorios/acompanhamento-remessas/:page"
          allowedRoutes={['user']}
          permission={[
            { EMBARCADOR: [EMBARCADOR.OPERACIONAL.CONSULTA_E_RELATORIO.ACOMPANHAMENTO_DE_REMESSA] },
          ]}
          exact
        >
          <AcompanhamentoRemessa />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/embarcadores/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE],
              ADMIN: [ADMIN.CADASTRO.EMBARCADOR],
            },
          ]}
        >
          <EmbarcadorGrid />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listagem-sat/:page?"
          allowedRoutes={['admin']}
          component={GridSat}
          permission={[{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA] }]}
        >
          <GridSat />
        </PrivateRoute>
        <PrivateRoute
          path="/consultar-sat"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.RESUMO_SAT],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RESUMO_SAT],
            },
          ]}
          exact
        >
          <ResumoSat />
        </PrivateRoute>
        <PrivateRoute
          path="/listagem-resumo-sat"
          allowedRoutes={['admin', 'user']}
          permission={[
            {
              ADMIN: [ADMIN.CONSULTA_RELATORIO.RESUMO_SAT],
              TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RESUMO_SAT],
            },
          ]}
          exact
        >
          <ListagemResumoSat />
        </PrivateRoute>
        <PrivateRoute
          path="/embarcador/add"
          allowedRoutes={['user', 'admin']}
          permission={[
            {
              TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE],
              ADMIN: [ADMIN.CADASTRO.EMBARCADOR],
            },
          ]}
          exact
        >
          <EmbarcadorAdd />
        </PrivateRoute>
        <PrivateRoute
          path="/centro-de-distribuicao/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.CD] }]}
        >
          <ConsultarCD />
        </PrivateRoute>
        <PrivateRoute
          path="/regional/:page"
          allowedRoutes={['user']}
          permission={[{ EMBARCADOR: [EMBARCADOR.CADASTRO.REGIONAL] }]}
        >
          <ConsultarRegional />
        </PrivateRoute>

        {/* Rotas dos Parametros feita por Cicero */}
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/servicoSmpt"
          permission={[{ ADMIN: [ADMIN.PARAMETRO.EMAIL] }]}
          exact
        >
          <ServicoSmpt />
        </PrivateRoute>
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/sistema"
          permission={[{ ADMIN: [ADMIN.PARAMETRO.SISTEMA] }]}
          exact
        >
          <ParametrosSistema />
        </PrivateRoute>
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/logs/integracao-dminer/:page"
          exact
        >
          <ParametrosLogs />
        </PrivateRoute>

        <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/integracao-xml/:page" exact>
          <ParametrosLogsXml />
        </PrivateRoute>

        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/integracao"
          permission={[{ ADMIN: [ADMIN.PARAMETRO.INTEGRACAO] }]}
          exact
        >
          <ParametrosIntegracao />
        </PrivateRoute>
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/automatizacao"
          permission={[{ ADMIN: [ADMIN.PARAMETRO.AUTOMATIZACAO] }]}
          exact
        >
          <ParametrosAutomatizacao />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/jornada-de-trabalho" exact>
          <ParametrosJornada />
        </PrivateRoute>
        {/* <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/webservice-sm/:page">
          <ParametrosLogs />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/webservice-pamcary/:page">
          <ParametrosLogs />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/integracao-dminer/:page">
          <ParametrosLogs />
        </PrivateRoute> */}

        {cookies.get('userSession') ? (
          <Route path="*" render={() => <Redirect to="/home/1" />} />
        ) : (
          <Route path="*" render={() => <Redirect to="/" />} />
        )}
      </Switch>
    </div>
  );
};

export default RoutesSideBar;
