/* eslint-disable camelcase */
import { DatePicker } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import Table from '../../components/TableComponent';
import { Tela } from '../../components/Tela';
import axios from '../../config/axiosMaquina';
import Input from '../../components/Input';

interface LogResultVehicle {
  occurrence: string;
  anttResponse: {
    city: string;
    ufRntrc: string;
    document: string;
    typeRntrc: string;
    resultFound: string;
    rntrcNumber: string;
    anttSituation: string;
    vehicleMessage: string;
    transporterName: string;
    registrationDate: string;
    vehicleAnttSituation: string;
  };

  indexVehicle: string;
  plateVehicle: string;
  renajudDetail: {
    restrictions: [];
  };
  infractionDetail: {
    infractions: [];
  };
  occurrenceDetail: {
    occurrences: [];
  };
  validationStatusUf: number;
  validationStatusOwner: number;
  validationStatusChassi: number;
  validationStatusRenavam: number;
  validationStatusDocumentImage: number;
}

interface LogRaw {
  xldg_cd_id: number;
  xldg_dt_create: string;
  xldg_json_request: string;
  xldg_json_response: string;
  xldg_tx_type: string;
}

interface LogType {
  logs: LogRaw[];
  last_log_time: string;
  log_date: string;
}

export const ParametrosLogsXml = () => {
  const { page }: any = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = data.slice(indexOfFirstPost, indexOfLastPost);

  const currentDate = new Date().toISOString().slice(0, 10);

  const [dataInicio, setDataInicio] = useState(currentDate);
  const [dataFim, setDataFim] = useState(currentDate);

  const listLogs = async () => {
    if (!dataInicio || !dataFim) return;

    const startDate = `${dataInicio}T00:00:00.000-03:00`;
    const endDate = `${dataFim}T23:59:59.999-03:00`;
    setLoading(true);
    try {
      const response = await axios.get(`/xml-log-gr`);

      const logsDminerParsed = response.data.map((log: LogType) => {
        const date = moment(log.log_date).format('DD/MM/YYYY');
        const date_last_log_time = moment(log.last_log_time).format('DD/MM/YYYY HH:mm:ss');

        return {
          log_date: date,
          last_log_time: date_last_log_time,
          logs: log.logs,
        };
      });

      setData(logsDminerParsed);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listLogs();
  }, [dataInicio, dataFim]);

  const paginate = (pageNumber: number) => {
    history.push(`/parametros/logs/integracao-xml/${pageNumber}`);
  };

  const renderName = () => {
    if (pathname.includes('webservice-sm')) {
      return { name: 'WebService S.M', path: 'Parâmetros > LOGs > WebService S.M' };
    }
    if (pathname.includes('webservice-pamcary')) {
      return { name: 'WebService Pamcary', path: 'Parâmetros > LOGs > WebService Pamcary' };
    }
    if (pathname.includes('integracao-dminer')) {
      return { name: 'Integração Dminer', path: 'Parâmetros > Logs > Log da pesquisa' };
    }
    return { name: '', path: '' };
  };

  const { name, path } = renderName();

  const handleDownloadLogTxt = (item: LogType) => {
    console.log(item);

    const logsFormatted = item.logs
      .map((log) => {
        return `
  Data:
  ${moment(log.xldg_dt_create).format('DD/MM/YYYY HH:mm:ss')}

  Request:
  ${log.xldg_json_request}

  Resposta:
  ${log.xldg_json_response}

  ----------------------------------------------------------------------------------------------
  `;
      })
      .join('\n');

    const fileContent = `Logs:\n${logsFormatted}`;

    const element = document.createElement('a');
    const file = new Blob([fileContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `Log-XML-${item.log_date}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Tela
      nome="Integração Xml"
      caminho="Parâmetros > Logs > Integração Xml"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={data.length}
      paginate={paginate}
      currentPage={page}
    >
      {currentData.length && (
        <Table
          headers={{ log_date: 'Arquivo', last_log_time: 'Última Atualização' }}
          items={currentData}
          customRenderers={{
            log_date: (item) => {
              const rawName = item.log_date;
              return (
                <Button variant="link" size="sm" onClick={() => handleDownloadLogTxt(item)}>
                  {`Log-${rawName}.log`}
                </Button>
              );
            },
          }}
        />
      )}
    </Tela>
  );
};
