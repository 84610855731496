import { Dispatch, SetStateAction } from 'react';
import { errorsMessages } from '../../constants/Messages';
import { validaVazioNulo } from '../../util/Validacoes/Validacoes';

type Veiculo =
  | {
      anttRntrc: string;
      atualizacaoAutomatica: boolean;
      chassi: string;
      emplacamentoUf: number;
      id: number;
      placa: string;
      proprietarioId: number;
      renavam: string;
      veiculoTipo: number;
      veiculoTipoNome: string;
      tipoFrota?: string | null;
    }
  | undefined;

type InputErrors = {
  veiculo: string;
  carreta1: string;
  carreta2: string;
  placa: string;
  modelo: string;
  proprietarioId: string;
  renavam: string;
  chassi: string;
  veiculoTipo: string;
  anttRntrc: string;
  emplacamentoUf: string;
  atualizacaoAutomatica: string;
  centroCusto: string;
};

type Props = {
  veiculo: Veiculo;
  setVeiculoInputErrors: Dispatch<SetStateAction<InputErrors>>;
  flagHabilitarPesquisaAnttRntrc: boolean;
};

const veiculoFieldsValidation = ({
  veiculo,
  setVeiculoInputErrors,
  flagHabilitarPesquisaAnttRntrc,
}: Props): boolean => {
  let valid = true;
  const errors: InputErrors = {} as InputErrors;

  if (!veiculo) {
    return true;
  }

  if (!validaVazioNulo(veiculo.renavam)) {
    errors.renavam = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (veiculo.renavam.length < 8) {
    errors.renavam = errorsMessages.input.renavamInvalid;
    valid = false;
  }

  if (veiculo.chassi.length < 17) {
    errors.chassi = errorsMessages.input.chassiInvalid;
    valid = false;
  }

  if (!validaVazioNulo(veiculo.chassi)) {
    errors.chassi = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(veiculo.emplacamentoUf) || veiculo.emplacamentoUf?.toString() === '0') {
    errors.emplacamentoUf = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(veiculo.proprietarioId) || veiculo.proprietarioId?.toString() === '0') {
    errors.proprietarioId = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(veiculo.anttRntrc) && flagHabilitarPesquisaAnttRntrc) {
    console.log({ error: errorsMessages.input.nullOrEmpty });
    errors.anttRntrc = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (veiculo.anttRntrc && veiculo.anttRntrc.length < 9) {
    errors.anttRntrc = 'Este campo precisa ter 9 digitos';
    valid = false;
  }

  if (veiculo.anttRntrc && veiculo.anttRntrc.length === 9) {
    const anttSplit = veiculo.anttRntrc.split('');
    const anttValidacao = anttSplit.every((char) => char === veiculo.anttRntrc[0]);
    if (anttValidacao) {
      errors.anttRntrc = 'Este campo não pode conter digitos repetidos';
      valid = false;
    }
  }

  if (
    veiculo.veiculoTipoNome === 'Selecione' ||
    !validaVazioNulo(veiculo.veiculoTipo) ||
    veiculo.veiculoTipo?.toString() === '0'
  ) {
    errors.veiculoTipo = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  setVeiculoInputErrors(errors);
  return valid;
};

export default veiculoFieldsValidation;
